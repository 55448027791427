// axiosInstance.ts
import axios from 'axios';
import { STORAGE_KEY } from "../configs/constants";

const baseAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default baseAxios;
