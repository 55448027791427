import { STORAGE_KEY } from "../configs/constants";
import baseAxios from "./axios_instance";

export interface User {
    [key: string]: any;
}


interface LoginRequestBody {
    username: string,
    password: string
}

class AuthService {

    async login({ username, password }: LoginRequestBody): Promise<any> {
        try {
            const response = await baseAxios.post('/auth/token',
                { grant_type: "password", username, password });
            const { access_token: accessToken, refresh_token: refreshToken, user: data } = response.data;
            if (accessToken) {
                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
                localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
                localStorage.setItem(STORAGE_KEY.USER, JSON.stringify(data));
                
                return { user: data };
            }
            return null;
        } catch (error) {
            console.error("Login failed", error);
            return null;
        }
    }

    async renewAccessToken(): Promise<string | null> {
        try {
            const refreshToken = await localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
            const response = await baseAxios.post('/auth/token', {
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            });
            const { access_token: newAccessToken, refresh_token: newRefreshToken } = response.data;
            if (newAccessToken) {
                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, newAccessToken);
                localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, newRefreshToken);

                return newAccessToken;
            }
            else {
                this.logout();
            }
            return null
        } catch (error) {
            console.error("Get Access Token failed", error);
            return null;
        }
    }

    getMyProfileInfo = async () => {
        let result = null;
        const userInStorage = localStorage.getItem('USER');
        if (userInStorage) {
            const user = JSON.parse(userInStorage);
            result = user;
        }
        return result;
    };

    async logout() {
        try {
            const response = await baseAxios.post(`/auth/invalidate`);
            return !!response.data;
        } catch (error) {
            return false;
        }
    }

    isAuthenticated(): boolean {
        return !!localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }

    getToken(): string | null {
        return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }

    getRefresh(): string | null {
        return localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
    }

    getUser(): User | null {
        const userData = localStorage.getItem(STORAGE_KEY.USER);
        return userData ? JSON.parse(userData) : null;
    }
}

export const authService = new AuthService();
