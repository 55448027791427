import dayjs from "dayjs";
import { generalDateTimeFormat } from "../configs/constants";

export const formatDateTime = (date: string | Date | null | undefined) => {
    const formattedDate = dayjs(date).format(generalDateTimeFormat);
    return formattedDate === 'Invalid Date' ? generalDateTimeFormat : '-';
};


export const convertISOStringtoFormattedDateTime = (dateString: string) => {
    if (!dateString) {
        return '-';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "-";
    }

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
}