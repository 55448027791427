import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import { AppSettingsProvider, } from './stores/context/app_setting_context';
import CustomThemeProvider from './stores/providers/CustomThemeProvider';
import { AuthProvider } from './stores/providers/auth_provider';
import { LinearProgressProvider } from './stores/providers/linear_progress_provider';
import { createRoot } from 'react-dom/client';
import { BackdropProgressProvider } from './stores/providers/backdrop_progress_provider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const portalDiv = document.getElementById('root')!;
const root = createRoot(portalDiv);
const Based = () => {
  return (
    <Router>
      <BackdropProgressProvider>
        <LinearProgressProvider>
          <AuthProvider>
            <AppSettingsProvider>
              <CustomThemeProvider>
                <CssBaseline />
                <App />
              </CustomThemeProvider>
            </AppSettingsProvider>
          </AuthProvider>
        </LinearProgressProvider>
      </BackdropProgressProvider>
    </Router>
  );
};

root.render(
  <React.StrictMode>
    <Based />
  </React.StrictMode>,
);
