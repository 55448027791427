import { User } from "./auth_service";
import { Roles } from "../models/role";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import baseAxios from "./axios_instance";
import { USER_ROLE_NAME_FROM_CODE } from "../configs/constants";

export type UserRequestOptions = {
    page?: number;
    perPage?: number;
    roles?: string[]
    ownerId?: string | number | undefined
    userType?: string;
};

export type PermissionsResponse = {
    id: number;
    name: string;
    position: number;
    permissions: string[];
};


class UserService {
    async listUser(params?: UserRequestOptions) {
        try {
            const page = params?.page || 1;
            const perPage = params?.perPage || 25;
            const roles = params?.roles ? params.roles.map((role) => role).join(",") : "";
            const ownerId = params?.ownerId;
            const response = await baseAxios.get("/users", { params: { page, perPage, roles, ownerId } });
            const updatedUsers = {
                meta: response?.data.meta,
                data: {
                    meta: response?.data.meta || {},
                    data: response.data.data?.map((user: User) => {
                        const fullName = `${user.firstName} ${user.lastName}`;
                        const roleName = USER_ROLE_NAME_FROM_CODE[user.roleCode as keyof typeof USER_ROLE_NAME_FROM_CODE];
                        return {
                            ...user,
                            fullName,
                            roleName
                        };
                    }) as User[]
                }
            };
            console.log(updatedUsers.data);
            return updatedUsers.data;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    async getUserById(id: number | string): Promise<User | null> {
        try {
            const response = await baseAxios.get<User>(`/users/${id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    async createUser(user: User) {
        const response = await baseAxios.post("/users", user);
        return response.data;
    }

    async updateUser(userId: number, user: User) {
        const response = await baseAxios.put(`/users/${userId}`, user);
        return response.data;
    }

    async deleteUser(userId: number): Promise<AxiosResponse | null> {
        try {
            const response = await baseAxios.delete(`/users/${userId}`);
            return response;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async getRoles(): Promise<Roles[] | []> {
        try {
            const response = await baseAxios.get(`/roles`);
            return response.data;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    async getRoleById(id: number): Promise<Roles | null> {
        try {
            const response = await baseAxios.get(`/roles/${id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async getPermissions(): Promise<PermissionsResponse[] | []> {
        try {
            const response = await baseAxios.get(`/permissions`);
            return response.data;
        } catch (error) {
            console.log(error);
            return [];
        }
    }
}

const userService = new UserService();
export default userService;