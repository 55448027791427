import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { useAppSettings } from '../context/app_setting_context';

const CustomThemeProvider = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
    const { state } = useAppSettings();

    const theme = createTheme({
        palette: state.theme.palette,
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default CustomThemeProvider;
