import * as React from 'react';
import { AppBar, Button, IconButton, IconButtonProps, PaletteMode, styled, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AppToolbarMenuSelector from '../form/app_toolbar_menu_selector';
import DialogProvider from '../transition_modal/dialog_provider';
import { ChevronLeft } from '@mui/icons-material';

interface TemplateFrameProps {
    showCustomTheme?: boolean;
    toggleCustomTheme?: (theme: boolean) => void;
    mode?: PaletteMode;
    toggleColorMode: () => void;
    children: React.ReactNode;
    toggleDrawerVisibility?: () => void;
    isDrawerOpen?: boolean;
}

export default function AppNavbar({ toggleDrawerVisibility, isDrawerOpen }: TemplateFrameProps) {
    const StyledAppBar = styled(AppBar)(({ theme }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
        borderBottom: '1px solid',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
        backgroundImage: 'none',
        zIndex: theme.zIndex.drawer + 1,
        flex: '0 0 auto',
    }));

    const handleChange = (): void => {
    }

    return (
        <><DialogProvider>
            <StyledAppBar>
                <Toolbar
                    variant="dense"
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        p: '8px 12px',
                    }}>
                    <div style={{ backgroundColor: 'transpa', width: '100%', paddingLeft: isDrawerOpen ? 223 : 0 + 18 }}>
                        <Button onClick={toggleDrawerVisibility}>
                            {isDrawerOpen ? <ChevronLeft /> : <MenuIcon />}
                        </Button>
                    </div>
                    <>
                        <IconButton
                            size="small"
                            aria-label=""
                            component="a"
                            href="/material-ui/getting-started/templates/"
                            sx={{ display: { xs: 'auto', sm: 'none' } }}
                        >
                        </IconButton></>
                    <AppToolbarMenuSelector handleChange={handleChange} />
                </Toolbar>
            </StyledAppBar>
        </DialogProvider>
        </>
    );
}