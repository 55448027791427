import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';
import { dark, light } from '../../components/theme/app_theme_scheme';

interface AppSettingsState {
    theme: typeof dark | typeof light;
    language: string;
}

type AppSettingsAction =
    | { type: 'SET_THEME'; payload: any }
    | { type: 'SET_LANGUAGE'; payload: string };

const initialState: AppSettingsState = {
    theme: dark,
    language: 'en',
};

const appSettingsReducer = (
    state: AppSettingsState,
    action: AppSettingsAction
): AppSettingsState => {
    switch (action.type) {
        case 'SET_THEME':
            return { ...state, theme: action.payload };
        case 'SET_LANGUAGE':
            return { ...state, language: action.payload };
        default:
            return state;
    }
};

const AppSettingsContext = createContext<{
    state: AppSettingsState;
    dispatch: Dispatch<AppSettingsAction>;
} | undefined>(undefined);

export const AppSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(appSettingsReducer, initialState);

    return (
        <AppSettingsContext.Provider value={{ state, dispatch }}>
            {children}
        </AppSettingsContext.Provider>
    );
};

// Hook to access the global state
export const useAppSettings = () => {
    const context = useContext(AppSettingsContext);
    if (!context) {
        throw new Error('useAppSettings must be used within an AppSettingsProvider');
    }
    return context;
};




