import React from 'react';
import { Box, Chip, Stack, useTheme } from '@mui/material';
import { BorderColorOutlined } from '../../../node_modules/@mui/icons-material/index.d';

export interface RoleOption {
    value: string;
    visible: boolean;
    disabled?: boolean;
    name?: string;
    selected?: boolean;
    requiredPermission?: string;
}

interface RoleSelectorProps {
    value: string[]; // Updated to require an array
    filterRoles: RoleOption[];
    handleFilterRoleChange: (selectedRoles: string[]) => void; // Update the handler type
}

const RoleSelector: React.FC<RoleSelectorProps> = ({ value, filterRoles, handleFilterRoleChange }) => {
    const theme = useTheme()
    const handleChipClick = (role: RoleOption) => {
        const newValue = value?.includes(role.value)
            ? value.filter(val => val !== role.value)
            : [...value, role.value];

        handleFilterRoleChange(newValue);
    };
    const { primary, info } = theme.palette

    return (
        <Box>
            <Stack direction="row" spacing={1}>
                {filterRoles?.map((role) => {
                    const isSelected = value.includes(role.value) ?? false;
                    return <Chip
                        sx={{
                            borderColor: isSelected ? info.main : primary.main,
                            color: isSelected ? info.main : primary.main,
                        }}
                        color={isSelected ? 'primary' : 'default'}
                        key={role.value}
                        label={`${role.name} ${isSelected ? "✔" : ""}`}
                        variant="outlined"
                        onClick={() => handleChipClick(role)}
                        disabled={role.disabled}
                    />
                }

                )}
            </Stack>
        </Box>
    );
};

export default RoleSelector;
