import { useTheme } from '@emotion/react';
import { Backdrop, CircularProgress, createTheme } from '@mui/material';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useAppSettings } from '../context/app_setting_context';

interface BackdropProgressContextProps {
    isFullScreenLoading: boolean;
    startFullScreenLoading: () => void;
    stopFullScreenLoading: () => void;
}

const FullScreenLoadingContext = createContext<BackdropProgressContextProps | undefined>(undefined);

export const BackdropProgressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isFullScreenLoading, setIsFullScreenLoading] = useState<boolean>(false);
    const startFullScreenLoading = () => setIsFullScreenLoading(true);
    const stopFullScreenLoading = () => setIsFullScreenLoading(false);

    return (
        <FullScreenLoadingContext.Provider value={{ isFullScreenLoading, startFullScreenLoading, stopFullScreenLoading }}>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={isFullScreenLoading}
                onClick={stopFullScreenLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </FullScreenLoadingContext.Provider>
    );
};

export const useFullScreenLoading = (): BackdropProgressContextProps => {
    const context = useContext(FullScreenLoadingContext);
    if (!context) {
        throw new Error('useFullScreenLoading must be used within a LoadingProvider');
    }
    return context;
};
