
export const deepGoldPalette = {
    black: '#000',
    obsidianBlack: '#0D0D0D',
    midnightOlive: '#0D0C07',
    deepDarkGrey: '#212121',
    deepTwilightBlue: '#020f14',
    abyssBlack: '#020a0e',
    midnightNavy: '#0F2940',
    darkOceanBlue: '#0a101a',
    stormyBlue: '#0c1f34',
    deepTealBlue: '#102945',
    slateBlue: '#202d40',
    royalBlue: '#002884',
    skyBlue: '#2652A9',
    electricBlue: '#00a2ff',
    charcoalGrey: '#444444',
    espressoBrown: '#322f29',
    agedBronzeDark: '#423B21',
    agedBronze: '#594A2E',
    goldenOchre: '#A6800F',
    desertSand: '#BF8924',
    goldenMustard: '#c18d21',
    richAmber: '#bd7418',
    sunlitOrange: '#e89c31',
    sunlitOrangeLight: '#DEC664',
    lightCaramel: '#D9B779',
    crimsonRed: '#8c0e0f',
    vibrantRed: '#ff4343',
    mintGreen: '#7bffb4',
    smokyBlue: '#677188',
    ashGrey: '#6b6969',
    coolGrey: '#A6A6A6',
    lightSilver: '#b3b3b3',
    softSilver: '#cfced0',
    frostWhite: '#F2F2F2',
    pureWhite: '#fefefe',
    white: '#fff',
};

export const blueYelloGreyLightPalette = {
    blue1: '#303740',
    blue2: '#0476D9',
    skyBlue: '#0468BF',
    white: '#EBEFF2',
    yellow: '#F2C744',
    red: '#8C041D',
    black: '#0A0C0D',
    green: '#0F5929',
    darkGrey: '#303740',
    snowWhite: '#F2F2F2',
}

export const brand = {
    50: 'hsl(210, 100%, 95%)',
    100: 'hsl(210, 100%, 92%)',
    200: 'hsl(210, 100%, 80%)',
    300: 'hsl(210, 100%, 65%)',
    400: 'hsl(210, 98%, 48%)',
    500: 'hsl(210, 98%, 42%)',
    600: 'hsl(210, 98%, 55%)',
    700: 'hsl(210, 100%, 35%)',
    800: 'hsl(210, 100%, 16%)',
    900: 'hsl(210, 100%, 21%)',
};

export const gray = {
    50: 'hsl(220, 35%, 97%)',
    100: 'hsl(220, 30%, 94%)',
    200: 'hsl(220, 20%, 88%)',
    300: 'hsl(220, 20%, 80%)',
    400: 'hsl(220, 20%, 65%)',
    500: 'hsl(220, 20%, 42%)',
    600: 'hsl(220, 20%, 35%)',
    700: 'hsl(220, 20%, 25%)',
    800: 'hsl(220, 30%, 6%)',
    900: 'hsl(220, 35%, 3%)',
};

export const green = {
    50: 'hsl(120, 80%, 98%)',
    100: 'hsl(120, 75%, 94%)',
    200: 'hsl(120, 75%, 87%)',
    300: 'hsl(120, 61%, 77%)',
    400: 'hsl(120, 44%, 53%)',
    500: 'hsl(120, 59%, 30%)',
    600: 'hsl(120, 70%, 25%)',
    700: 'hsl(120, 75%, 16%)',
    800: 'hsl(120, 84%, 10%)',
    900: 'hsl(120, 87%, 6%)',
};

export const orange = {
    50: 'hsl(45, 100%, 97%)',
    100: 'hsl(45, 92%, 90%)',
    200: 'hsl(45, 94%, 80%)',
    300: 'hsl(45, 90%, 65%)',
    400: 'hsl(45, 90%, 40%)',
    500: 'hsl(45, 90%, 35%)',
    600: 'hsl(45, 91%, 25%)',
    700: 'hsl(45, 94%, 20%)',
    800: 'hsl(45, 95%, 16%)',
    900: 'hsl(45, 93%, 12%)',
};

export const red = {
    50: 'hsl(0, 100%, 97%)',
    100: 'hsl(0, 92%, 90%)',
    200: 'hsl(0, 94%, 80%)',
    300: 'hsl(0, 90%, 65%)',
    400: 'hsl(0, 90%, 40%)',
    500: 'hsl(0, 90%, 30%)',
    600: 'hsl(0, 91%, 25%)',
    700: 'hsl(0, 94%, 18%)',
    800: 'hsl(0, 95%, 12%)',
    900: 'hsl(0, 93%, 6%)',
};

export const colorScheme = {
    appBarBackgroundBlueColor: '#003D9A',
    acceptButtonColor: '#1480FF',
    bgPinDotActiveColor: '#1480FF',
    biometricAuthButton: '#327ED9',
    buttonColorActive2: '#1480FF',
    activePageIndicator: '#2B518F',
    almostFull: '#FF5C00',
    appBackgroundColor: '#08152D',

    appBarBgColor: '#0F2237',
    appBarTextColorWithBlueBg: '#FEFEFE',
    bgCarausel: '#102A4D',
    bgColor: '#001B3B',
    bgErrorColor: '#E32933',

    bgPinDotInactiveColor: '#E7EBF2',

    blurColor: '#B6B6B6',

    buttonColorActive: '#8EF8FF',
    buttonColorDisable2: '#00000000',
    buttonColorDisable: '#D1D1D1',
    buttonColorTab: '#465459',
    buttonFailColor: '#E32933',
    buttonTextColor: '#001B3B',
    buttonTextColorDisable: '#828282',
    cardBgColor: '#0D2542',
    cardColorActive: '#192E47',
    cardColorTab: '#1E3F67',
    checkBoxUnfillColor: '#B6B6B6',
    contactUsButtonColor: '#01B901',
    correctTextColor: '#00DE24',
    darkBGColor: '#08152D',
    darkBGText: '#8EF8FF',
    darkText: '#003756',
    detailText: '#161616',
    detailText2: '#002F74',
    failTextColor: '#232121',
    filterColor: '#000000',
    formBg: '#3E5F86',
    freeLot: '#C3FFCD',
    freeLotText: '#222222',
    goldBadge: '#6D3700',
    goldTierDarkBg: '#FFE6A6',
    goldTierLightBg: '#FFECCE',
    goldTierText: '#393528',
    grey100: '#AEAEAE',
    iconColor: '#000000',
    iconFilledColor: '#FEFEFE',
    imageLogoShadow: '#005DA0',
    imageShaderMaskColor: '#2a4668',
    inactivePageIndicator: '#DEDEDE',
    inputBgColor: '#10253E',
    inputFieldBg: '#10253E',
    instructionBlue: '#0074FF',
    instructionDescription: '#001B3B',
    lightText: '#FAFAFA',
    loginBackground: '#002336',
    modalTextColor: '#001B3B',
    navBarColor: '#2A4566',
    negativeTextColor: '#003D99',
    parkingEmptyPageForeGroundColor: '#828282',
    parkingPaginationIndex: '#0078FF',
    parkingOpenTimeGradientEnd: '#DFFFFB',
    parkingOpenTimeGradientStart: '#D6F0FF',
    pinBTColor: '#3E638F',
    pinDotActiveBorderColor: '#78E7FF',
    pinDotErrorBorderColor: '#FF454F',
    pinDotInactiveBorderColor: '#CDCDCD',
    pinLinkText: '#6BA6FF',
    pointCardBg: '#2D476B',
    pointCardTextColor: '#FFFFFF',
    pointDividerColor: '#D9D9D9',
    pointExpireColor: '#8EF8FF',
    pointExpireTextColor: '#F4F4F4',
    pointHistoryCalendarTextColor: '#001B3B',
    pointHistoryCurrentTextColor: '#FFFFFF',
    pointHistoryDateColor: '#8498B3',
    pointHistoryPointBg: '#3E638F',
    pointHistoryPointTextColor: '#8EF8FF',
    pointProgressBgColor: '#001D40',
    pointProgressText: '#FEFEFE',
    profileGoldBorderGradient1: '#FFE6A6',

    profileGoldBorderGradient2: '#6D3700',
    profileSilverBorderGradient1: '#FFFFFF',
    profileSilverBorderGradient2: '#787878',
    red100: '#FF0101',
    red75: '#FF454E',
    scrollBarColor: '#4B6186',
    searchBoxText: '#002159',
    searchParkingBg: '#08152D',
    searchParkingDivider: '#D9D9D9',
    searchParkingDropDown: '#0D2542',
    searchTableColor: '#1F5BA3',
    serviceFee: '#E8F7FF',
    shadow: '#001A40',
    silverTierDarkBg: '#8E8E8E',
    silverTierLightBg: '#F4F4F4',
    subText: '#737373',
    textColor: '#FEFEFE',
    textColorDisable: '#BBBBBB',
    textColorError: '#FF454E',
    textColorValidated: '#15FF11',
    textFieldBorderColor: '#C0CFE6',
    textFieldErrorBorderColor: '#FF454E',
    rippleSplashColor: '#233D5B',
};

export const sampleColor = [
    '#9ab9df',
    '#8090A6',
    '#325573',
    '#BFBFBF',
    '#F2F2F0'
]
export const sampleColor2 = [
    '#0B1926',
    '#19403C',
    '#648C6E',
    '#BF9765',
    '#F2F2F2'
]
export const sampleColor3 = [
    '#2F735A',
    '#39594D',
    '#D9CA7E',
    '#8C6330',
    '#F2F2F2',
]

export const sumColor = [
    '#0B1926',
    '#19403C',
    '#8C6330',
    '#2F735A',
    '#39594D',
    '#325573',
    '#648C6E',
    '#BF9765',
    '#8090A6',
    '#D9CA7E',
    '#9ab9df',
    '#BFBFBF',
    '#F2F2F2',
    '#F2F2F2',
    '#F2F2F0'
]
