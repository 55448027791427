// AppPagination.tsx
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';

interface AppPaginationProps {
    page: number;
    count: number;
    onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    label?: string; // Optional label
}

const AppPagination: React.FC<AppPaginationProps> = ({ page, count, onPageChange, label = 'Page' }) => {
    return (
        <Stack spacing={2} alignItems="left">
            <Typography>
                {label}: {page}
            </Typography>
            <Pagination count={count} page={page} onChange={onPageChange} />
        </Stack>
    );
};

export default AppPagination;
