import React, { useEffect } from 'react';
import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useAppSettings } from '../../stores/context/app_setting_context';
import { dark, light } from '../theme/app_theme_scheme';
import PromptTypo from '../typography/typography_prompt';
import { Key, PersonRounded } from '@mui/icons-material';
import { useDialog } from '../transition_modal/dialog_provider';
import CustomSelectItem from './custom_select_item';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../dialog/change_password_form';
import { useAuth } from '../../stores/providers/auth_provider';
import EditProfileForm from '../dialog/profile_update_form';
import userService from '../../services/user_service';
import { useLoading } from '../../stores/providers/linear_progress_provider';

interface CustomSelectProps {
    handleChange: (event: SelectChangeEvent) => void;
}

const AppToolbarMenuSelector: React.FC<CustomSelectProps> = ({ handleChange }) => {
    const { state, dispatch } = useAppSettings();
    const { logout, user, roles, setRoles, userToken } = useAuth();
    const { startLoading, stopLoading, isLoading } = useLoading();
    const { openDialog } = useDialog();
    const navigate = useNavigate();
    const getNextTheme = () => {
        if (state.theme === dark) return light;
        else return dark;
    }
    const handleTheme = () => {
        dispatch({ type: 'SET_THEME', payload: getNextTheme() });
    };
    const handleNavigation = (route: string) => {
        navigate(route);
    };


    const handleLogout = async () => {
        console.log("logged out by direct menu")
        await logout();
        handleNavigation('/login');
    }
    const handleOpenDialog = ({ type = '' }) => {
        if (type === 'change-password') openDialog(<ChangePasswordForm />);
        if (type === 'edit-profile') openDialog(<EditProfileForm />)
    };

    const getRoles = async () => {
        startLoading();
        const rolesResponse = await userService.getRoles();
        setRoles(rolesResponse ?? [{ id: 0, name: 'default', code: 'default', permissions: [] }]);
        stopLoading();
    }
    const borderColor = state.theme.palette.action.hover;
    const color = state.theme.palette.text.primary;

    useEffect(() => {
        if (roles.length === 0) {
            // !isLoading && getRoles();
        }
        if (!userToken) {
            logout();
        }
    }, [])


    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl variant="outlined" sx={{ minWidth: 180 }}>
                <CustomSelectItem handleChange={handleChange} color={color} borderColor={borderColor}>
                    <MenuItem value="custom" aria-readonly disabled>
                        <PromptTypo>
                            {user?.firstName ?? ''} {user?.lastName ?? ''}
                        </PromptTypo>
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenDialog({ type: 'edit-profile' })}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonRounded style={{ marginRight: '8px' }} />
                            <PromptTypo style={{ cursor: 'pointer' }}>
                                ข้อมูล
                            </PromptTypo>
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleTheme}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {state.theme === dark ? <WbSunnyRoundedIcon style={{ marginRight: '8px' }} /> :
                                <ModeNightRoundedIcon fontSize="small" style={{ marginRight: '8px' }} />}
                            <PromptTypo style={{ cursor: 'pointer' }}>
                                เปลี่ยนธีม
                            </PromptTypo>

                        </div>
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenDialog({ type: 'change-password' })}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Key style={{ marginRight: '8px' }} />
                            <PromptTypo style={{ cursor: 'pointer' }}>
                                เปลี่ยนรหัสผ่าน
                            </PromptTypo>
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LogoutRoundedIcon style={{ marginRight: '8px' }} />
                            <PromptTypo style={{ cursor: 'pointer' }}>
                                ออกจากระบบ
                            </PromptTypo>
                        </div>
                    </MenuItem>
                </CustomSelectItem>
            </FormControl>
        </Box>
    );
};

export default AppToolbarMenuSelector;
