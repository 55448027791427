import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledFillButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    color: '#fefefe',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        backgroundColor: '#b3b3b3',
        color: theme.palette.primary.contrastText,
    },
}));

export const StyledOutlineButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        borderColor: '#b3b3b3',
        color: '#b3b3b3',
    },
}));


export const StyledTextButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    border: '0px',
    outline: '0px',
    textTransform: 'none',

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.secondary.main,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
    },
    '&.Mui-disabled': {
        color: '#b3b3b3',
    },
}));


// export const StyledDatepickerButton = styled(Button)(({ theme }) => ({
//     backgroundColor: theme.palette.action.selected,
//     color: '#fefefe',
//     borderColor: theme.palette.action.active,
//     fontWeight: 'bold',
//     '&:hover': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     '&:active': {
//         backgroundColor: theme.palette.primary.main,
//     },
//     '&.Mui-disabled': {
//         backgroundColor: '#b3b3b3',
//         color: theme.palette.primary.contrastText,
//     },
// }));


export const StyledOutlineDatepickerButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
    },
    '&:active': {
        backgroundColor: theme.palette.action.selected,
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        borderColor: '#b3b3b3',
        color: '#b3b3b3',
    },
}));
