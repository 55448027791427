import baseAxios from "./axios_instance";
import { removeEmptyParam } from "./helper";

export type CreditRequestResponseData = {
    ownerId: number;
    bankAccountId: number;
    amount: number;
    requestDatetime: string;
    transferDatetime: string;
    requestRemark: string;
}
export type TopUpRequestBody = {
    ownerId: number;
    type: 'TOPUP' | 'ADJUST'
    bankAccountId: string | number;
    amount: string | number;
    requestDatetime: string;
    transferDatetime: string;
    requestRemark?: string;
}

export type CreditRequestParams = {
    type?: 'TOPUP' | 'REQUEST';
    status?: 'REQUEST' | 'approved' | 'rejected ' | 'requested';
    dateFrom?: string;
    dateTo?: string;
    page?: number;
    perPage?: number;
}

export type CreditRequestData = {
    id: number;
    ownerId: number;
    amount: number;
    bankAccountId: number;
    status: string;
    requestDatetime: string;
    transferDatetime: string;
    processDatetime: string;
    processUserId: number;
    requestRemark: string;
    adminRemark: string;
    transactionId: number;
}

export type PaginationMeta = {
    limit?: number;
    page?: number;
    totalCount?: number;
    totalPages?: number;
}

export type ResponseWithMeta<T> = {
    data?: CreditRequestData[] | T[] | null;
    meta?: PaginationMeta
}

export type ApproveOrRejectTopUpRequest = {
    status: string;
    processDatetime: string;
    processUserId: number,
    adminRemark: string;
    transactionId: number,
    transaction: Record<string, any> | null;
}

export type ApproveOrRejectResponse = {
    id: number;
    ownerId: number;
    amount: number;
    bankAccountId: number;
    status: string;
    requestDatetime: string;
    transferDatetime: string;
    processDatetime: string;
    processUserId: number
    requestRemark: string;
    adminRemark: string;
    transactionId: number;
}

export type BankAccountResponse = {
    id: number;
    bankAccountNumber: string | number;
    bankAccountName: string;
    bankName: string;
    minimumAmount: 100
}

export type CreditTransactionRequestOptions = {
    page: string | number;
    perPage: string | number;
    ownerId: string | number;
    bankAccountId: string | number;
}

export type ApproveOrRejectReq = {
    type: "APPROVED" | "REJECTED";
}

class CreditService {
    async creditTopUpRequest(req: TopUpRequestBody): Promise<CreditRequestResponseData | null> {
        try {
            const response = await baseAxios.post<CreditRequestResponseData>("/credits/requests", req);
            return response.data;
        } catch (error) {
            console.log(error);
            return null
        }
    }
    async listCreditRequests(params: CreditRequestParams): Promise<ResponseWithMeta<CreditRequestData> | null> {
        const paramsWithOutEmpty = removeEmptyParam<CreditRequestParams>(params);

        try {
            const response = await baseAxios.get<ResponseWithMeta<CreditRequestData>>("/credits/requests", { params: paramsWithOutEmpty });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async getCreditRequestById(id: number | string) {
        try {
            const response = await baseAxios.get<CreditRequestData>(`/credits/requests/${id}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async creditApproval(id: string | number, status: 'APPROVED' | 'REJECTED') {
        try {
            const response = await baseAxios.put<CreditRequestData>(`/credits/requests/${id}`, { id, status });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async approveOrRejectTopUpRequest(req: ApproveOrRejectTopUpRequest): Promise<ApproveOrRejectResponse | null> {
        try {
            const response = await baseAxios.put<ApproveOrRejectResponse>(`/credits/requests/{requestId}`, { req });
            return response.data;
        } catch (error) {
            console.log(error);
            return null
        }
    }

    async listCreditTransactions(params: CreditTransactionRequestOptions): Promise<ResponseWithMeta<any[]> | null> {
        try {
            const response = await baseAxios.get<ResponseWithMeta<any[]>>("/credits", { params });
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async listCreditTxns(params: CreditTransactionRequestOptions): Promise<ResponseWithMeta<any[]> | null> {
        try {
            const response = await baseAxios.get<ResponseWithMeta<any[]>>("/credits/txn", { params });
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    async listBankAccount(): Promise<BankAccountResponse[] | []> {
        try {
            const response = await baseAxios.get<BankAccountResponse[]>("/banks",);
            return response.data ?? [];
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    // removeEmptyParams(params: CreditRequestParams): void {
    //     Object.keys(params).forEach((key) => {
    //         if (!params[key as keyof CreditRequestParams]) {
    //             delete params[key as keyof CreditRequestParams];
    //         }
    //     });
    // }
}
const creditService = new CreditService();
export default creditService;

