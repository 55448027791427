// App.tsx
import React from 'react';
import MainTemplate from './components/template/body';
import RouterOutlet from './components/template/router_outlet';
import DialogProvider from './stores/providers/dialog_provider';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login_page';
import useAxiosInterceptors from './stores/hooks/axios_interceptor';

const App: React.FC = ({ toggleTheme }: any) => {
  useAxiosInterceptors();
  return (
    <DialogProvider>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <MainTemplate>
              <RouterOutlet />
            </MainTemplate>
          }
        />
      </Routes>
    </DialogProvider>
  );
};
export default App;
