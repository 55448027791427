export type ActionName = 'EDIT' | 'DELETE' | 'CREATE' | 'LIST' | 'DISABLE' | 'VIEW' | 'PROFILE' | 'PERMISSION' | 'REQUEST' | 'REQUEST_APPROVE_REJECT';
export type ModuleName = 'USER' | 'CREDIT'; 
export type TargetName = 'SUPERADMIN' | 'OWNER' | 'STAFF' | 'AGENT';

export const moduleNameDisplayText: Record<ModuleName | string, string> = {
    USER: 'ผู้ใช้งาน',
    CREDIT: 'เครดิต',
};

export const actionNameDisplayText: Record<ActionName | string, string> = {
    EDIT: 'แก้ไขข้อมูล',
    DELETE: 'ลบ',
    CREATE: 'สร้าง',
    LIST: 'ดูรายการ',
    DISABLE: 'ระงับการใช้งาน',
    VIEW: 'ดูข้อมูล',
    PROFILE: 'โปรไฟล์',
    PERMISSION: 'แก้ไขสิทธิ์',
    REQUEST: 'สร้างคำขอ',
    REQUEST_APPROVE_REJECT: 'อนุมัติ/ปฏิเสธคำขอ',
};

export const targetNameDisplayText: Record<TargetName | string, string> = {
    OWNER: 'ระดับ Owner',
    STAFF: 'ระดับ Staff',
    AGENT: 'ระดับ Agent',
    SUPERADMIN: "ระดับ Super_Admin",
    SUPER_ADMIN: "ระดับ Super_Admin",
};

export const permissionDisplayText = (permission: string) => {
    const parts = permission.split('_');

    const module = parts[0] as ModuleName;
    const action = parts.slice(1, -1).join('_') as ActionName;
    const target = parts[parts.length - 1] as TargetName;

    const displayAction = actionNameDisplayText[action] ?? '';
    const displayModule = moduleNameDisplayText[module] ?? '';
    const displayTarget = targetNameDisplayText[target] ?? '';

    return `${displayAction}${displayModule}${displayTarget}`;
};

export const permissionDisplayTextV2 = (permission: string) => {
    const moduleKey = Object.keys(moduleNameDisplayText).find(key =>
        permission.includes(key)
    ) ?? '' as string;
    const targetKey = Object.keys(targetNameDisplayText).find(key =>
        permission.includes(key)
    ) ?? '' as string;
    const modifiedPermission = permission.replace(moduleKey, '').replace(targetKey, '').replace(/^_+|_+$/g, '');

    const actionKeys = Object.keys(actionNameDisplayText).filter(key =>
        modifiedPermission.includes(key)
    ) as ActionName[];

    const displayModule = moduleKey ? moduleNameDisplayText[moduleKey as ModuleName] : '';
    const displayAction = actionKeys.length > 0 ? actionKeys.map(action => actionNameDisplayText[action]).join(', ') : '';
    const displayTarget = targetKey ? targetNameDisplayText[targetKey as TargetName] : '';

    return `${displayAction}${displayModule}${displayTarget}`.trim();
}

export const groupPermissionByPrefixString = (
    userPermission: string[],
    groupKeys?: ModuleName[]
) => {
    const groupedActions: { [key: string]: string[] } = {};
    userPermission.forEach(action => {
        const prefix = action.split("_")[0];
        if (!groupedActions[prefix]) {
            groupedActions[prefix] = [];
        }
        groupedActions[prefix].push(action);
    });
    return groupedActions;
};

// Translate permission string to display text
export const translatePermissionToDisplayText = (permission: string) => {
    try {
        return permissionDisplayTextV2(permission);
    } catch (error) {
        console.log("error", error);
        return '';
    }
};

// Example usage
const userPermissions = [
    "CREDIT_LIST_OWNER_CREDIT",
    "CREDIT_REQUEST_APPROVE_REJECT",
    "USER_CREATE_AGENT",
    "USER_EDIT_AGENT",
    "USER_VIEW_SUPERADMIN"
];

userPermissions.forEach(permission => {
    console.log(translatePermissionToDisplayText(permission)); // Outputs the display text
});
