import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import PromptTypo from '../typography/typography_prompt';

const StyledComponent = styled(TextField)(({ theme }) => ({
    // '& fieldset': {
    //     borderColor: theme.palette.primary.dark,
    // },
    // '&:hover fieldset': {
    //     borderColor: theme.palette.primary.dark,
    // },
    // '&.Mui-focused fieldset': {
    //     borderColor: theme.palette.primary.main,
    // },
    // '& .MuiInputBase-root': {
    //     height: '45px', // Set the height of the input field
    // },
}));

const StyledTextField: React.FC<TextFieldProps> = (props) => {
    return (
        <StyledComponent
            variant="outlined"
            {...props}
        />
    );
};

interface StyledTextFieldWithErrorTextProps {
    name: string;
    type?: string;
    placeholder: string;
    value: string;
    error: boolean;
    errorText?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    props?: TextFieldProps,
    disabled?: boolean;
    onBlur?: (() => void) | undefined;
    endAdornment?: React.ReactElement;
}

const StyledTextFieldWithErrorText: React.FC<StyledTextFieldWithErrorTextProps> = ({
    name,
    type,
    placeholder,
    value,
    error,
    errorText,
    onChange,
    props,
    disabled,
    onBlur
}) => {
    return (
        <>
            <StyledTextField
                fullWidth
                type={type || 'text'}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                error={error}
                disabled={disabled}
                onBlur={onBlur}
                {...props}
            />
            {error && errorText && (
                <PromptTypo component="span" style={{ color: 'red', fontSize: '12px' }}>{errorText}</PromptTypo>
            )}
        </>
    );
};

export default StyledTextFieldWithErrorText;
