export function formatNumberString(raw: string | number): string {
    const input = `${raw}`
    if (!input) {
        return input;
    }
    const num = parseFloat(input?.replace?.(/,/g, '').trim());
    if (isNaN(num)) {
        return input;
    }

    return num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}