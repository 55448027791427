import React, { ReactNode, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import ReusableTable, { DataRow } from '../components/table/hoverable_table_row';
import { Box, Button, Divider, IconButton, MenuItem, Pagination, Stack } from '@mui/material';
import { Add, AdjustOutlined, AdjustRounded, Approval, Close, Delete, Edit, FormatLineSpacingRounded, Lock, ManageAccountsRounded, ManageSearchRounded, NoTransferRounded, Password, RequestPage, RequestQuote, RequestQuoteRounded, TouchAppRounded, Visibility } from "@mui/icons-material";
import MenuPopup from '../components/menu_popup/menu_popup';
import PromptTypo from '../components/typography/typography_prompt';
import { User } from '../configs/constants';
import { Col, Row, StyledContainer } from '../components/styled/styled_flex_container';
import CreditTableFilter from '../components/table_filters/credit_table_filter';
import { formatNumberString } from '../components/spring/string_helper';
import userService from '../services/user_service';
import { StyledFillButton, StyledOutlineButton } from '../styled/filled_button';
import creditService from '../services/credit_service';
import { useDialog } from '../stores/providers/dialog_provider';
import CreditAdjustmentDialog from '../components/dialog/credit_adjustment_dialog';



interface TopUpRequestPageProps { }

interface TopUpListData {
    id: number;
    requestedAt: string;
    createdAt: string;
    targetBankAccount: string;
    amount: number;
    remark: string;
    status: string;
    closed: string;
    adminNote: string;
    action?: JSX.Element | null;
}


interface Column {
    label: string;
    field: string;
    align?: 'left' | 'right' | 'center';
    highlighten?: boolean;
    render?: (props: any) => ReactNode;
}

const columns: Column[] = [
    { label: 'เลขที่', field: 'id' },
    { label: 'ชื่อ Owner', field: 'fullName', highlighten: true },
    { label: 'Total Balance', field: 'totalBalance', highlighten: true },
    { label: 'สถานะ', field: 'status' },
    { label: '', field: 'actions', align: 'right' },
];

const CreditTransactionPage = ({ }: TopUpRequestPageProps) => {
    const [owners, setOwners] = useState<User[]>([]);
    const [listMetadata, setListMetadata]: [any, any] = useState({ page: 1, perPage: 10 });
    useEffect(() => {
        listOwner(listMetadata)
    }, []);
    const { openDialog } = useDialog();
    const [dialogId, setDialogId] = useState<number | null>(null);

    async function listOwner(requestOptions: { page?: any, perPage?: any }) {
        const { page, perPage } = requestOptions;
        const users = await userService.listUser({ roles: ['W'], page, perPage: 25 }) as { meta: Record<string, any>, data: User[] };
        const userList = users?.data ?? []
        const metaData = users.data ?? {}
        const dataWithAdditionalColumns = await Promise.all(userList.map(async (item: User) => ({//+
            ...item,
            actions: _getTopUpRowAction(item),
            totalBalance: 0
            // transactions: await creditService.listCreditTxns({ page: 1, perPage: 99, ownerId: item.ownerId })//+
        })));

        console.log({ dataWithAdditionalColumns });
        setOwners(dataWithAdditionalColumns);
        setListMetadata(metaData);

    }

    function handleOnOpenAdjustDialog(user: User) {
        const dialogId = openDialog(<CreditAdjustmentDialog user={user} />);
        setDialogId(dialogId);
    }
    const handleAdjustment = () => { }
    const handleApprove = (id: number | string) => { }
    const handleReject = (id: number | string) => { }

    function _getTopUpRowAction(user: User) {
        return <Stack direction={'row'} width='100%' justifyContent='flex-end' sx={{}}>
            <StyledOutlineButton>
                <Stack direction='row'>
                    <PromptTypo variant='body1'>
                        ดูรายระเอียด
                    </PromptTypo>
                    <ManageSearchRounded sx={{ margin: '0px 8px' }} />
                </Stack>
            </StyledOutlineButton>
            <Box sx={{ width: '16px' }} />
            <StyledOutlineButton onClick={() => handleOnOpenAdjustDialog(user)}>
                <Stack direction='row'>
                    <PromptTypo variant='body1'>
                        จัดการเครดิต
                    </PromptTypo>
                    <FormatLineSpacingRounded sx={{ margin: '0px 8px' }} />
                </Stack>
            </StyledOutlineButton>
        </Stack>
    }

    function handleOnPageChange(e: React.ChangeEvent<any>, page: number) {
        listOwner({ page });
    }

    return (
        <StyledContainer>
            <Col>
                <Row centerY style={{ justifyContent: 'space-between' }}>
                    <PromptTypo variant="h5">รายการเครดิต</PromptTypo>
                </Row>
                <Col>
                    <CreditTableFilter sx={{ width: '100%' }} />
                </Col>
                <ReusableTable
                    columns={columns}
                    data={owners as unknown as DataRow[] || []}
                />
                {listMetadata && (
                    <Pagination style={{ marginTop: "1em" }} count={listMetadata.totalPages} defaultPage={listMetadata.page}
                        onChange={handleOnPageChange} />
                )}
            </Col>
        </StyledContainer>
    )
}

export default CreditTransactionPage