// login_page.tsx
import React, { useState } from 'react';
import { Alert, Backdrop, Box, Button, Container, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../stores/providers/auth_provider';
import { LogoDev } from '@mui/icons-material';
import PromptTypo from '../components/typography/typography_prompt';
import { StyledOutlineButton } from '../styled/filled_button';
import DialogService from '../components/dialog/confirmation_by_sweet_alert2';
import Swal from 'sweetalert2';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('owner');
    const [password, setPassword] = useState('password123');
    const [errorSnack, setErrorSnack] = useState('');
    const [formError, setFormError] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleHideError = () => {
        setErrorSnack('');
        setFormError(true);
    };

    const handleLogin = async () => {
        if (username?.length === 0 || password?.length === 0) {
            setErrorSnack('กรุณากรอกข้อมูลให้ครบถ้วน');
        }
        try {
            const res = await login(username, password);
            if (res?.['successful']) {
                navigate('/', { replace: true })
            } else {
                setFormError(true);
                setErrorSnack('username หรือ password ไม่ถูกต้อง')
            }
        } catch (error) {
            setErrorSnack('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง');
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            !formError && handleLogin();
        }
    };

    React.useEffect(() => {
        setFormError(false);
    }, [username, password]);
    return (
        <Container
            style={{ width: '100vw' }}
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '40px'
            }}
        >
            {errorSnack !== '' && (
                <Alert
                    variant='filled'
                    severity="error"
                    onClick={handleHideError}
                    sx={{ position: 'absolute', top: 16, zIndex: 1, maxWidth: '460px' }}
                >
                    {errorSnack}
                </Alert>
            )}

            <Backdrop
                invisible={false}
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backgroundColor: 'transparent' })}
                open={errorSnack !== ''}
                onClick={handleHideError}
            >
                {/* If you want to show any additional content in the backdrop */}
            </Backdrop>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 8,
                    width: '100%',
                    maxWidth: '460px'
                }}
            >
                <LogoDev style={{ fontSize: '72px' }} />
                <div style={{ height: '16px' }} />
                <PromptTypo component="h1" variant="h4">
                    เข้าสู่ระบบ
                </PromptTypo>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    error={formError}
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    error={formError}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Button
                    disabled={formError}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </Box>
        </Container>
    );
};

export default LoginPage;
