import { Select, styled, useTheme } from "@mui/material"
import { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import { ReactNode } from "react";

interface CustomSelectProps<T> {
    children?: ReactNode;
    handleChange?: (event: SelectChangeEvent<T>) => void;
    color?: any,
    borderColor?: any,
    placeholder?: string;
    value?: T;
}

const CustomSelectItem = ({ children, color, borderColor, placeholder, handleChange }: CustomSelectProps<any>) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    return <Select
        labelId="company-select"
        id="company-simple-select"
        value="custom"
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Select company' }}
        fullWidth
        placeholder={placeholder ?? ''}
        defaultValue={placeholder}
        sx={{
            '&.MuiOutlinedInput-root': {
                borderColor: borderColor ?? primaryColor,
                '& fieldset': {
                    borderColor: borderColor ?? primaryColor,
                },
                '&:hover fieldset': {
                    borderColor: borderColor ?? primaryColor,
                    color: color,
                },
                '&.Mui-focused fieldset': {
                    borderColor: borderColor ?? primaryColor,
                },
                '&.Mui-unfocused fieldset': {
                    borderColor: borderColor ?? primaryColor,
                },
            },
            [`& .${selectClasses.select}`]: {
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                paddingLeft: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor ?? primaryColor,
            },
            '&.Mui-focused': {
                outline: 'none', // Remove focus outline
                boxShadow: 'none', // Remove focus shadow
            },
            maxHeight: 40,
            width: 215,
        }}
    >
        {children}
    </Select>
}

export default CustomSelectItem;