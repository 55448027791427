import { useEffect, useState } from "react";
import { STORAGE_KEY } from "../../configs/constants";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user_service";
import { authService } from "../../services/auth_service";

export interface UserToken {
    accessToken?: string;
    refreshToken?: string;
}

export const useUserToken = () => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState<UserToken>({
        accessToken: localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN) || undefined,
        refreshToken: localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN) || undefined,
    });

    useEffect(() => {
        const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN) ?? userToken?.accessToken ?? '';
        const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN) ?? userToken?.refreshToken ?? '';
        setUserToken({ accessToken, refreshToken });
    }, [userToken.accessToken]);

    const updateTokens = (tokens: Partial<UserToken>) => {
        if (tokens.accessToken) localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, tokens.accessToken);
        if (tokens.refreshToken) localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, tokens.refreshToken);
        setUserToken((prev) => ({ ...prev, ...tokens }));
    };

    const clearTokenAndRedirectToLoggin = () => {
        authService.logout().then(async (res: boolean) => {
            if (res) {
                await localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
                await localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
                await localStorage.removeItem(STORAGE_KEY.USER);
                navigate('/login', { replace: true });
            }
        });
    }

    return { userToken, updateTokens, clearTokenAndRedirectToLoggin };
};

