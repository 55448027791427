// SpringHero.tsx
import React, { ReactNode } from 'react';
import {
    useTransition,
    useSpring,
    useChain,
    config,
    animated,
    useSpringRef,
} from '@react-spring/web';
import styles from './styles.module.css';
import { useAppSettings } from '../../stores/context/app_setting_context';

interface Item {
    css: string;
}

const data: Item[] = [
    { css: 'transparent' },
    { css: 'blue' },
    { css: 'green' },
    // Add more items as needed
];

interface SpringHeroProps {
    children: ReactNode;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpringHero: React.FC<SpringHeroProps> = ({ children, open, setOpen }) => {
    const { state } = useAppSettings();
    const springApi = useSpringRef();
    const { size, ...rest } = useSpring({
        ref: springApi,
        config: config.stiff,
        from: { size: '20%', background: 'transparent' },
        to: {
            size: open ? '100%' : '20%',
            background: 'transparent',
        },
    });

    const transApi = useSpringRef();
    const transition = useTransition(open ? data.slice(0, 1) : [], { // Only show the first item
        ref: transApi,
        trail: 400 / data.length,
        from: { opacity: 0, scale: 0 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0 },
    });

    useChain(open ? [springApi, transApi] : [transApi, springApi], [
        0,
        open ? 0.1 : 0.6,
    ]);

    return (
        <div className={styles.wrapper}>
            <animated.div
                style={{ ...rest, width: size, height: size }}
                className={styles.container}
            >
                {transition((style, item) => (
                    item.css && (
                        <animated.div
                            className={styles.item}
                            style={{ ...style, background: state.theme.palette.primary.dark }}
                        >
                            {children}
                        </animated.div>
                    )
                ))}
            </animated.div>
        </div>
    );
};

export default SpringHero;
