// MainTemplate.tsx
import React, { useEffect, useState } from 'react';
import { CssBaseline, AppBar, Toolbar, LinearProgress, Backdrop } from '@mui/material';
import AppNavbar from './nav_bar';
import SideMenu from './side_menu';
import { useLoading } from '../../stores/providers/linear_progress_provider';

interface MainTemplateProps {
    children?: React.ReactNode;
}

interface LinearProgressLoadingProps {
    value?: number;
    variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
}

const drawerWidth = 240;

const MainTemplate: React.FC<MainTemplateProps> = ({ children }) => {
    const [height, setHeight] = useState(window.innerHeight);
    const [drawerWidthState, setDrawerWidthState] = useState<number>(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
    const { isLoading, startLoading, stopLoading } = useLoading();
    const [linearProgressState, setLinearProgressState] = useState<LinearProgressLoadingProps>({ value: 0, variant: 'determinate' });

    const handleOpenDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const stopLoad = () => {
        setLinearProgressState({ value: 100, variant: 'determinate' });
    };
    const startLoad = () => {
        setLinearProgressState({ value: 0, variant: 'indeterminate' });
    };
    useEffect(
        () => {
            if (isLoading) startLoad(); // Loading
            if (!isLoading) stopLoad(); // Stop Load
        },
        [isLoading]
    )
    useEffect(
        () => {
            if (isDrawerOpen) setDrawerWidthState(drawerWidth); // Loading
            if (!isDrawerOpen) setDrawerWidthState(0); // Stop Load
        },
        [isDrawerOpen]
    )
    return (
        <div>
            <CssBaseline />
            <AppBar position="fixed">
                <AppNavbar
                    isDrawerOpen={isDrawerOpen}
                    toggleDrawerVisibility={handleOpenDrawer}
                    showCustomTheme={true} toggleCustomTheme={function (theme: boolean): void {
                        throw new Error('Function not implemented.');
                    }} mode={'light'} toggleColorMode={function (): void {
                        throw new Error('Function not implemented.');
                    }} children={undefined}></AppNavbar>
                <LinearProgress
                    variant={linearProgressState.variant ?? "indeterminate"}
                    value={linearProgressState?.value ?? 0} />
            </AppBar>
            <SideMenu isOpen={isDrawerOpen} />
            <main style={{ flexGrow: 1, padding: '16px', width: `calc(100% - ${drawerWidthState}px)`, height: `calc(${height}px - ${56}px)`, marginLeft: drawerWidthState }}>
                <div style={{ padding: '16px', minWidth: '400px', height: '100%' }}>
                    <Toolbar />
                    {children}
                </div>
            </main>
        </div>
    );
};

export default MainTemplate;
