import { createTheme } from '@mui/material/styles';
import { brand, colorScheme, deepGoldPalette, gray, sampleColor2 } from './colors';


// black: '#000',
// obsidianBlack: '#0D0D0D',
// midnightOlive: '#0D0C07',
// deepGrey: '#212121',
// deepTwilightBlue: '#020f14',
// abyssBlack: '#020a0e',
// midnightNavy: '#0F2940',
// darkOceanBlue: '#0a101a',
// stormyBlue: '#0c1f34',
// deepTealBlue: '#102945',
// slateBlue: '#202d40',
// royalBlue: '#002884',
// skyBlue: '#2652A9',
// electricBlue: '#00a2ff',
// charcoalGrey: '#444444',
// espressoBrown: '#322f29',
// agedBronzeDark: '#423B21',
// agedBronze: '#594A2E',
// goldenOchre: '#A6800F',
// desertSand: '#BF8924',
// goldenMustard: '#c18d21',
// richAmber: '#bd7418',
// sunlitOrange: '#e89c31',
// sunlitOrangeLight: '#DEC664',
// lightCaramel: '#D9B779',
// crimsonRed: '#8c0e0f',
// vibrantRed: '#ff4343',
// mintGreen: '#7bffb4',
// smokyBlue: '#677188',
// ashGrey: '#6b6969',
// coolGrey: '#A6A6A6',
// lightSilver: '#b3b3b3',
// softSilver: '#cfced0',
// frostWhite: '#F2F2F2',
// pureWhite: '#fefefe',
// white: '#fff',

const dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: deepGoldPalette.lightCaramel,
            light: deepGoldPalette.slateBlue,
            dark: deepGoldPalette.deepDarkGrey,
            contrastText: deepGoldPalette.pureWhite,
        },
        secondary: {
            main: deepGoldPalette.electricBlue,
            dark: deepGoldPalette.midnightNavy,
            contrastText: '#fff',
        },
        background: {
            default: deepGoldPalette.espressoBrown,
            paper: deepGoldPalette.deepDarkGrey
        },
        text: {
            primary: deepGoldPalette.lightCaramel,
            secondary: deepGoldPalette.frostWhite,
            disabled: deepGoldPalette.softSilver,
        },
        action: {
            hover: deepGoldPalette.slateBlue,
            selected: deepGoldPalette.goldenMustard,
            focus: deepGoldPalette.slateBlue,
            active: deepGoldPalette.lightCaramel,
            disabled: deepGoldPalette.smokyBlue,
            disabledBackground: '#0000040',
        },
        grey: gray,
        divider: deepGoldPalette.charcoalGrey,
    },
    typography: {
        fontFamily: 'prompt',
        body1: {
            color: deepGoldPalette.frostWhite,
        },
        body2: {
            color: deepGoldPalette.frostWhite,
        },
        subtitle1: {
            color: deepGoldPalette.frostWhite,
        },
        subtitle2: {
            color: deepGoldPalette.frostWhite,
        },
    },
});

const light = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: sampleColor2[2],
            light: '#cae2ff',
            dark: sampleColor2[4],
            contrastText: sampleColor2[0],
        },
        secondary: {
            main: sampleColor2[1],
            light: sampleColor2[1],
            dark: brand[200],
            contrastText: sampleColor2[4],
        },
        background: {
            default: sampleColor2[4],
            paper: '#f2f2f2',
        },
        text: {
            primary: '#11171D',
            secondary: '#003972',
            disabled: '#fefefe',
        },
        error: {
            main: '#ff4343',
        },
        action: {
            hover: '#9fb2ca',
            selected: sampleColor2[2],
            focus: sampleColor2[2],
        }

    },
    typography: {

        fontFamily: 'prompt',
        body1: {
            color: deepGoldPalette.abyssBlack,
        },
        body2: {
            color: deepGoldPalette.midnightOlive,
        },
        subtitle1: {
            color: deepGoldPalette.black,
        },
        subtitle2: {
            color: deepGoldPalette.black,
        },
    }
});

export { light, dark };
