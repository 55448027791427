import { Dialog } from "@mui/material";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface DialogContextType {
    openDialog: (content: ReactNode) => number;
    closeDialog: (id?: number | null) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export function useDialog() {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useDialog must be used within a DialogProvider");
    }
    return context;
}

export default function DialogProvider({ children }: { children: ReactNode }) {
    const [dialogs, setDialogs] = useState<
        { id: number; content: ReactNode; open: boolean }[]
    >([]);

    const createDialog = (content: ReactNode) => {
        const id = Date.now(); // Unique id for each dialog
        setDialogs((prevDialogs) => [
            ...prevDialogs,
            { id, content, open: true },
        ]);
        return id;
    };

    const closeDialog = (id: number | null) => {
        setDialogs((prevDialogs) =>
            prevDialogs.map((dialog) =>
                dialog.id === id ? { ...dialog, open: false } : dialog
            )
        );
    };

    const clearAllDialogs = () => {
        setDialogs([]);  // Close all dialogs
    }

    const contextValue = {
        openDialog: (content: ReactNode) => createDialog(content),
        closeDialog: () => setDialogs([]), // Close all dialogs
    };

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {dialogs.map((dialog) => (
                <DialogContainer
                    key={dialog.id}
                    open={dialog.open}
                    onClose={() => closeDialog(dialog.id)}
                >
                    {dialog.content}
                </DialogContainer>
            ))}
        </DialogContext.Provider>
    );
}

function DialogContainer({
    children,
    open,
    onClose,
}: {
    children: ReactNode;
    open: boolean;
    onClose: () => void;
}) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
            {children}
        </Dialog>
    );
}
