import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';
import PromptTypo from '../typography/typography_prompt';
import { SwalLoadingSpecial2 } from './swal_alert';



const DialogService = {
    open: async (options: SweetAlertOptions, handleConfirm: () => void) => {
        const result = await Swal.fire(options);

        if (result.isConfirmed) {
            handleConfirm?.();
        }
    },

    loading: () => {
        SwalLoadingSpecial2();
    },

    confirmation: (title: string, description?: string, html?: string | HTMLElement | JQuery | undefined): SweetAlertOptions => {
        const option = html ? html : {
            title,
            text: description,
            confirmButtonText: 'ยืนยัน!',
            cancelButtonText: "ยกเลิก",
            showCancelButton: true,
        };

        return option;
    },
    notice: (title?: string, description?: string): SweetAlertOptions => {
        return {
            title,
            text: description ?? '',
            confirmButtonText: 'ดำเนินการต่อ',
            showCancelButton: false,

        };
    },
    openCustomAlert: (render: React.ReactElement, ...[...other]): SweetAlertOptions => {
        return { html: render, ...other }
    }
};

export default DialogService;
