import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../stores/providers/auth_provider';

interface ProtectedRouteProps {
    redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = '/login' }) => {
    const navigate = useNavigate();
    const { isAuthenticated, logout, user } = useAuth();

    useEffect(() => {
        if (!isAuthenticated && !!!user) {
            logout().then(() => { navigate(redirectPath) });
        }
    }, [isAuthenticated, navigate, redirectPath]);

    return isAuthenticated ? <Outlet /> : null;
};

export default ProtectedRoute;