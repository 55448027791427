// EditProfileForm.tsx
import React, { useEffect, useState } from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { User } from "../../services/auth_service";
import { USER_ROLE, USER_ROLE_NAME } from "../../configs/constants";
import { rxPassword } from '../../configs/regex';
import { useAuth } from '../../stores/providers/auth_provider';

const formFields: AppFormData[] = [
    {
        name: 'roleCode', value: '', required: true, placeholder: 'Role', label: 'Role', inputType: 'select',
        choices: [
            { label: USER_ROLE_NAME[USER_ROLE.SUPER_ADMIN], value: USER_ROLE.SUPER_ADMIN, requiredPermission: `USER_CREATE_SUPER_ADMIN` },
            { label: USER_ROLE_NAME[USER_ROLE.OWNER], value: USER_ROLE.OWNER, requiredPermission: `USER_CREATE_OWNER` },
            { label: USER_ROLE_NAME[USER_ROLE.STAFF], value: USER_ROLE.STAFF, requiredPermission: `USER_CREATE_STAFF` },
            { label: USER_ROLE_NAME[USER_ROLE.AGENT], value: USER_ROLE.AGENT, requiredPermission: `USER_CREATE_AGENT` },
        ]
    },
    { name: 'firstName', value: '', required: true, placeholder: 'ชื่อ', label: 'ชื่อ' },
    { name: 'lastName', value: '', required: true, placeholder: 'นามสกุล', label: 'นามสกุล' },
    {
        name: 'phone',
        value: '',
        pattern: /^\d{10}$/,
        required: true,
        placeholder: 'เบอร์โทรศัพท์',
        label: 'เบอร์โทรศัพท์'
    },
    { name: 'email', value: '', pattern: /\S+@\S+\.\S+/, required: true, placeholder: 'อีเมล์', label: 'อีเมล์' },
    { name: 'username', value: '', pattern: /\S+/, required: true, placeholder: 'ชื่อผู้ใช้', label: 'Username' },
    { name: 'password', value: '', required: true, placeholder: 'รหัสผ่านสมาชิก', label: 'รหัสผ่านสมาชิก', inputType: 'password', pattern: rxPassword },
];

export type CreateUserFormProps = {
    user?: User
    userType?: string
    onSubmit?: (formData: Record<string, string | number>) => void | Promise<void> | undefined | null
};

const CreateUserForm: React.FC<CreateUserFormProps> = ({ user, onSubmit }) => {
    const [fields, setFields] = useState<AppFormData[]>([...formFields]);
    const { user: currentUser } = useAuth();
    useEffect(() => {
        if (user) {
            const filledFields = fields.map((field) => ({
                ...field,
                value: user[field.name],
                disabled: ["id", "role"].includes(field.name),
                required: field.name === "password" ? false : field.required, // ถ้าแก้ไข ก็คือจะไม่สนใจว่าใส่พาสเวิร์ดหรือไม่.. ถ้าใส่มาคือแก้ไขรหัสผ่าน
            }));
            setFields(filledFields);
        }
    }, [user])

    const handleFormSubmit = (data: Record<string, string | number>) => {
        const payload = {
            ...data,
            ownerId: currentUser.ownerId ?? currentUser.id
        }
        if (onSubmit) onSubmit(payload);
    };

    return (
        <FormBuilder formTitle={user ? "แก้ไขสมาชิก" : "เพิ่มสมาชิก"}
            fields={fields} onSubmit={handleFormSubmit} />
    );
};

export default CreateUserForm;
