export const appConstants = {}

export const regs = {
    phoneString: new RegExp('/D/g'),
}

export const USER_TYPE = {
    MEMBER: 'member',
    EMPLOYEE: 'employee',
}

export const USER_ROLE = {
    "SUPER_ADMIN": "SA",
    "OWNER": "W",
    "STAFF": "S",
    "AGENT": "A"
}
export const USER_ROLE_NAME_FROM_CODE = {
    "SA": "Super Admin",
    "W": "Owner",
    "S": "Staff",
    "A": "Agent"
}

export const USER_ROLE_NAME = {
    [USER_ROLE["SUPER_ADMIN"]]: "Super Admin",
    [USER_ROLE["OWNER"]]: "Owner",
    [USER_ROLE["STAFF"]]: "Staff",
    [USER_ROLE["AGENT"]]: "Agent"
}

export const STORAGE_KEY = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    USER: "USER",
}

export enum Role {
    SUPER_ADMIN = 'SUPER_ADMIN',
    OWNER = 'OWNER',
    STAFF = 'STAFF',
    AGENT = 'AGENT'
}
export enum RoleCode {
    SUPER_ADMIN = 'SA',
    OWNER = 'W',
    STAFF = 'S',
    AGENT = 'A'
}

export enum Permission {
    USER_CREATE_AGENT = 'USER_CREATE_AGENT',
    USER_CREATE_OWNER = 'USER_CREATE_OWNER',
    USER_CREATE_STAFF = 'USER_CREATE_STAFF',
    USER_EDIT_AGENT = 'USER_EDIT_AGENT',
    USER_EDIT_OWNER = 'USER_EDIT_OWNER',
    USER_EDIT_STAFF = 'USER_EDIT_STAFF',
    USER_LIST = 'USER_LIST',
    USER_LIST_AGENT = 'USER_LIST_AGENT',
    USER_LIST_OWNER = 'USER_LIST_OWNER',
    USER_LIST_STAFF = 'USER_LIST_STAFF',
    USER_PERMISSION_AGENT = 'USER_PERMISSION_AGENT',
    USER_PERMISSION_OWNER = 'USER_PERMISSION_OWNER',
    USER_PERMISSION_STAFF = 'USER_PERMISSION_STAFF',
    USER_PROFILE_CHANGE_PASSWORD = 'USER_PROFILE_CHANGE_PASSWORD',
    USER_PROFILE_EDIT = 'USER_PROFILE_EDIT',
}

export const {
    USER_CREATE_AGENT,
    USER_CREATE_OWNER,
    USER_CREATE_STAFF,
    USER_EDIT_AGENT,
    USER_EDIT_OWNER,
    USER_EDIT_STAFF,
    USER_LIST,
    USER_LIST_AGENT,
    USER_LIST_OWNER,
    USER_LIST_STAFF,
    USER_PERMISSION_AGENT,
    USER_PERMISSION_OWNER,
    USER_PERMISSION_STAFF,
    USER_PROFILE_CHANGE_PASSWORD,
    USER_PROFILE_EDIT,
} = Permission;

const rolePermissions: Record<Role, Permission[]> = {
    [Role.SUPER_ADMIN]: [
        USER_CREATE_AGENT,
        USER_CREATE_OWNER,
        USER_CREATE_STAFF,
        USER_EDIT_AGENT,
        USER_EDIT_OWNER,
        USER_EDIT_STAFF,
        USER_LIST,
        USER_LIST_AGENT,
        USER_LIST_OWNER,
        USER_LIST_STAFF,
        USER_PERMISSION_AGENT,
        USER_PERMISSION_OWNER,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.OWNER]: [
        USER_LIST_AGENT,
        USER_LIST_STAFF,
        USER_EDIT_AGENT,
        USER_EDIT_STAFF,
        USER_CREATE_AGENT,
        USER_CREATE_STAFF,
        USER_PERMISSION_AGENT,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.STAFF]: [
        USER_LIST_STAFF,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.AGENT]: [
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ]
};
export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    note?: string;
    createdAt: string;
    role?: Role;
    roleCode: RoleCode;
    roleId: number
    permissions: string[];
    ownerId?: number
    totalBalance?: number | string;
}



export const generalDateTimeFormat = 'DD/MM/YYYY - HH:mm';
export const generalDateFormat = 'DD/MM/YYYY';


export const SPACING_XS = '6px';
export const SPACING_SM = '12px';
export const SPACING_MD = '16px';
export const SPACING_LG = '24px';
export const SPACING_XL = '32px';
export const SPACING_XXL = '48px';


export const MOCK_PERF_KEY = [
    { id: 1, key: 'INHERIT_PERM_OWNER', allowedTorRoles: [] },
    { id: 2, key: 'INHERIT_PERM_STAFF', allowedTorRoles: [] },

    { id: 3, key: 'USER_CREATE_AGENT', allowedTorRoles: [2, 3] },
    { id: 4, key: 'USER_CREATE_OWNER', allowedTorRoles: [1] },
    { id: 5, key: 'USER_CREATE_STAFF', allowedTorRoles: [2, 3] },

    { id: 6, key: 'USER_DISABLE_AGENT', allowedTorRoles: [2, 3] },
    { id: 7, key: 'USER_DISABLE_OWNER', allowedTorRoles: [] },
    { id: 8, key: 'USER_DISABLE_STAFF', allowedTorRoles: [2, 3] },
    { id: 9, key: 'USER_DISABLE_SUPER_ADMIN', allowedTorRoles: [] },

    { id: 10, key: 'USER_EDIT_AGENT', allowedTorRoles: [2, 3] },
    { id: 11, key: 'USER_EDIT_OWNER', allowedTorRoles: [] },
    { id: 12, key: 'USER_EDIT_STAFF', allowedTorRoles: [2, 3] },

    { id: 13, key: 'USER_LIST', allowedTorRoles: [1] },
    { id: 14, key: 'USER_LIST_AGENT', allowedTorRoles: [2, 3] },
    { id: 15, key: 'USER_LIST_OWNER', allowedTorRoles: [1] },
    { id: 16, key: 'USER_LIST_STAFF', allowedTorRoles: [2, 3] },

    { id: 17, key: 'USER_PERMISSION_AGENT', allowedTorRoles: [2, 3] },
    { id: 18, key: 'USER_PERMISSION_OWNER', allowedTorRoles: [1] },
    { id: 19, key: 'USER_PERMISSION_STAFF', allowedTorRoles: [2, 3] },

    { id: 20, key: 'USER_PROFILE_CHANGE_PASSWORD', allowedTorRoles: [1, 2, 3, 4] },
    { id: 21, key: 'USER_PROFILE_EDIT', allowedTorRoles: [1, 2, 3, 4] },

    { id: 22, key: 'USER_VIEW_AGENT', allowedTorRoles: [2, 3] },
    { id: 23, key: 'USER_VIEW_OWNER', allowedTorRoles: [1] },
    { id: 24, key: 'USER_VIEW_STAFF', allowedTorRoles: [2, 3] },
    { id: 25, key: 'USER_VIEW_SUPER_ADMIN', allowedTorRoles: [1] },

    { id: 26, key: 'CREDIT_LIST_OWNER_CREDIT', allowedTorRoles: [1] },

    { id: 27, key: 'CREDIT_ADJUST_OWNER_CREDIT', allowedTorRoles: [1] },

    { id: 28, key: 'CREDIT_REQUEST_CREATE', allowedTorRoles: [2, 3] },

    { id: 29, key: 'CREDIT_REQUEST_LIST', allowedTorRoles: [2, 3] },

    { id: 30, key: 'CREDIT_REQUEST_APPROVE_REJECT', allowedTorRoles: [1] },

    { id: 31, key: 'CREDIT_TXN_LIST', allowedTorRoles: [1] }
]

export const defaultPagination = {
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPages: 0
}