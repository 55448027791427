import React, { useState } from 'react';
import {
    Typography,
    TextField,
    Button,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    IconButton,
    Alert,
    Container,
    styled,
    Divider
} from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import { useDialog } from '../stores/providers/dialog_provider';
import { useNavigate } from 'react-router-dom';
import PromptTypo from '../components/typography/typography_prompt';
import AppCalendar from '../components/calendar/app_calendar';
import { useLoading } from '../stores/providers/linear_progress_provider';
import { useFullScreenLoading } from '../stores/providers/backdrop_progress_provider';
import FullSCreenBackdropLoadingIndicator from '../components/backdrop/full_screen_loading';
import { SwalAlert, SwalLoading } from '../components/dialog/swal_alert';



export default function HomePage() {
    const [inputValue, setInputValue] = useState('');
    const { isLoading, startLoading, stopLoading } = useLoading();
    const { openDialog } = useDialog();
    const navigate = useNavigate();
    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };
    const handleClick = () => {
        setState((prevState) => ({
            ...prevState,
            open: true,
        }));
    };
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { open } = state;


    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            open: false,
        }));
    };



    const handleOpenDialog = () => {
        openDialog(
            <StyledContainer>
                <Typography variant="h6" gutterBottom>
                    My Dynamic Dialog
                </Typography>
                <Typography variant="body1">
                    This is a dynamically generated dialog.
                </Typography>
            </StyledContainer>
        );
    };

    const StyledContainer = styled(Container)(({ theme }) => ({
        padding: '24px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    }));

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                ยกเลิก
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                {/* Add an icon if needed */}
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Alert style={{ display: 'flex', alignItems: 'center' }}
                variant="filled" severity="error">
                <AlertTitle> <PromptTypo variant='h5'>
                    หวยรัฐบาล - 11-09-2024
                </PromptTypo></AlertTitle>
                <PromptTypo variant='body1'>
                    สถานะ :ปิดรับแล้ว
                </PromptTypo>
            </Alert>
            <Typography gutterBottom variant='h5'>
                ยินดีต้อนรับ
            </Typography>
            <TableContainer component={Container} style={{ marginTop: '20px', width: '50%' }}>
                <Container maxWidth="xl" style={{ marginTop: '2rem' }}>

                    <Divider>
                        <PromptTypo variant="h4" gutterBottom>
                            MUI TextField Example
                        </PromptTypo>
                    </Divider>
                    <TextField

                        label="Enter something"
                        variant="outlined"
                        fullWidth
                        // value="some value"
                        onChange={() => { }}
                        margin="normal"
                        error={true}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => SwalLoading()}
                        // onClick={() => isLoading ? stopLoading() : startLoading()}
                        style={{ marginTop: '1rem' }}
                    >
                        Submit
                    </Button>
                    <FullSCreenBackdropLoadingIndicator />
                </Container>
            </TableContainer>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                This is a success Alert with an encouraging title.
            </Alert>
            <Alert severity="warning">
                <AlertTitle>Success</AlertTitle>
                This is a success Alert with an encouraging title.
            </Alert>
            <Alert severity="info">
                <AlertTitle>Success</AlertTitle>
                This is a success Alert with an encouraging title.
            </Alert>
            <Alert severity="error">
                <AlertTitle>Success</AlertTitle>
                This is a success Alert with an encouraging title.
            </Alert>
            <TextField
                label="กรอกข้อความ"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <Button onClick={handleClick}>เปิดการแจ้งเตือน</Button>
            <Button onClick={handleOpenDialog}>เปิดการแจ้งเตือน</Button>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="บันทึกโน้ตแล้ว"
                action={action}

            />

            {/* Card */}
            <Card style={{ marginTop: '20px' }}>
                <CardContent>
                    <PromptTypo variant="h6">
                        การ์ด
                    </PromptTypo>
                    <PromptTypo color="textSecondary">
                        การ์ดเหมาะสำหรับแสดงเนื้อหาและการกระทำเกี่ยวกับเรื่องใดเรื่องหนึ่ง
                    </PromptTypo>
                </CardContent>
            </Card>
            {/* Repeated Table with Translations */}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>รายการ</TableCell>
                            <TableCell align="right">มูลค่า</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 1
                            </TableCell>
                            <TableCell align="right">100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 2
                            </TableCell>
                            <TableCell align="right">200</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>รายการ</TableCell>
                            <TableCell align="right">มูลค่า</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 1
                            </TableCell>
                            <TableCell align="right">100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 2
                            </TableCell>
                            <TableCell align="right">200</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>รายการ</TableCell>
                            <TableCell align="right">มูลค่า</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 1
                            </TableCell>
                            <TableCell align="right">100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 2
                            </TableCell>
                            <TableCell align="right">200</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>รายการ</TableCell>
                            <TableCell align="right">มูลค่า</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 1
                            </TableCell>
                            <TableCell align="right">100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ตัวอย่าง 2
                            </TableCell>
                            <TableCell align="right">200</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Additional Table Containers... */}
        </>
    );


}
