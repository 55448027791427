import { FolderOff } from "@mui/icons-material";
import { Box } from "@mui/material";
import PromptTypo from "../typography/typography_prompt";

export const EmptyComponent = ({ displayedText = 'ไม่พบข้อมูล' }: { displayedText?: string }) => {
    return (
        <Box marginTop={16} display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <FolderOff style={{ fontSize: 76, color: '#999' }} />
            <PromptTypo variant="h6" style={{ marginTop: 16 }}>
                {displayedText}
            </PromptTypo>
        </Box>
    );
};
