import { useEffect } from 'react';
import { STORAGE_KEY } from '../../configs/constants';
import { authService } from '../../services/auth_service';
import { useLoading } from '../providers/linear_progress_provider';
import baseAxios from '../../services/axios_instance';
import Swal from 'sweetalert2';
import dialogService from '../../components/dialog/confirmation_by_sweet_alert2';

const refreshAccessToken = async (): Promise<string | null> => {
    const newAccessToken = await authService.renewAccessToken();
    return newAccessToken;
};

const useAxiosInterceptors = () => {
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        const requestInterceptor = baseAxios.interceptors.request.use(
            async (config) => {
                handleLoading(true);
                const token = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                handleLoading(false);
                return Promise.reject(error);
            }
        );

        const handleLoading = (isLoading: boolean) => {
            isLoading ? startLoading() : stopLoading();
            isLoading && dialogService.loading();
            !isLoading && Swal.close();
        };

        const responseInterceptor = baseAxios.interceptors.response.use(
            (response) => {
                handleLoading(false);
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                handleLoading(false);

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    const newAccessToken = await refreshAccessToken();
                    if (newAccessToken === null) {
                        return Promise.reject(error);
                    }
                    localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, newAccessToken);
                    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
                    return baseAxios(originalRequest);
                }

                if (!error.response) {
                    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                        Swal.fire({
                            icon: 'error',
                            title: 'ข้อผิดพลาดของเครือข่าย',
                            text: 'มีปัญหากับการเชื่อมต่อเครือข่ายของคุณ กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ.',
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'ข้อผิดพลาดในการเชื่อมต่อ',
                            text: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ กรุณาลองใหม่อีกครั้งในภายหลัง.',
                        });
                    }
                } else if (error.response.status >= 500) {
                    Swal.fire({
                        icon: 'error',
                        title: 'ข้อผิดพลาดที่เซิร์ฟเวอร์',
                        text: 'เซิร์ฟเวอร์ไม่สามารถใช้งานได้ในขณะนี้ กรุณาลองใหม่อีกครั้งในภายหลัง.',
                    });
                }

                return Promise.reject(error);
            }
        );

        return () => {
            baseAxios.interceptors.request.eject(requestInterceptor);
            baseAxios.interceptors.response.eject(responseInterceptor);
        };
    }, [startLoading, stopLoading]);
};

export default useAxiosInterceptors;
