import React, { ReactNode } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Box, display, height, styled, SxProps } from '@mui/system';
import { Edit, Delete, Lock } from '@mui/icons-material';
import { useAppSettings } from '../../stores/context/app_setting_context';
import PromptTypo from '../typography/typography_prompt';
import { Row } from '../styled/styled_flex_container';
import { useTheme } from '@emotion/react';
import '../../../src/App.css';
import LinesEllipsis from 'react-lines-ellipsis';

// Define the props for the columns
interface Column {
    label: string;
    field: string;
    align?: 'left' | 'right' | 'center';
    highlighten?: boolean;
    render?: (props: any) => ReactNode;
}
export interface DataRow {
    [key: string]: any | null;
    id: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    status?: string;
    note?: string;
    createdAt?: string;
    actions?: TableActions;
}
interface TableActions {
    edit?: (row: DataRow) => void;
    delete?: (row: DataRow) => void;
    permission?: (row: DataRow) => void;
}

// Define the props for the ReusableTable component
interface ReusableTableProps {
    columns: Column[];
    data: DataRow[];
    sx?: SxProps;
}


const ReusableTable: React.FC<ReusableTableProps> = ({ columns, data, sx }) => {
    const { state } = useAppSettings();
    const theme = state.theme;
    const ellipsisStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const HoverableTableRow = styled(TableRow)(({ index }: { index: number }) => ({
        cursor: 'pointer',
        fontFamily: 'prompt',
        transition: 'background-color 0.3s',
        '&:hover': {
            elevation: 5,
            backgroundColor: `${theme.palette.secondary.dark}`, // Adjust the opacity for hover if needed
        },
    }));


    return (
        <Box sx={{ ...sx }} style={{ height: '75vh' }}>
            <Divider style={{ margin: '16px 0px' }} />
            <TableContainer component={Paper} style={{ height: '100%', overflowY: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ height: '70px' }}>
                            {columns.map((column) => (
                                <TableCell key={column.field}>
                                    <PromptTypo fontWeight="bold" variant="subtitle1">
                                        {column.label}
                                    </PromptTypo>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            const actions = row?.actions
                            return <HoverableTableRow index={index}
                                sx={{
                                    backgroundColor: index % 2 === 0 ?
                                        `#00000020` :
                                        'transparent'
                                }}
                                key={row.id}>
                                {columns.map((column) => {
                                    const renderField = column?.render ?
                                        column.render(row[column.field]) : row[column.field] ?? '-'

                                    return <TableCell component={'th'} scope='row' align={column.align ?? 'inherit'} key={column.field}>
                                        <PromptTypo
                                            variant="body2"
                                            fontWeight={column.highlighten ? 'bold' : 'normal'}
                                            color={column.highlighten ? state.theme.palette.primary.main : state.theme.typography.body2.color}>
                                            <LinesEllipsis
                                                style={ellipsisStyle}
                                                text={renderField}
                                                maxLine={0}
                                                ellipsis='...'
                                                trimRight
                                                basedOn='letters'
                                                component='p'
                                            />
                                        </PromptTypo>
                                    </TableCell>
                                })}
                                {actions && (
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            {actions?.edit && (
                                                <Tooltip title="แก้ไขข้อมูลผู้ใช้งาน" arrow>
                                                    <IconButton color="primary" aria-label="edit"
                                                        onClick={() => actions?.edit?.(row)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>

                                            )}
                                            {actions?.permission && (
                                                <Tooltip title="แก้ไขสิทธิ์ของผู้ใช้งาน" arrow>
                                                    <IconButton color="secondary" aria-label="delete"
                                                        onClick={() => actions?.permission?.(row)}>
                                                        <Lock />
                                                    </IconButton>
                                                </Tooltip>

                                            )}
                                            {actions?.delete && (
                                                <Tooltip title="ลบผู้ใช้งาน" arrow>
                                                    <IconButton color="error" aria-label="delete"
                                                        onClick={() => actions?.delete?.(row)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>

                                            )}
                                        </div>
                                    </TableCell>
                                )}
                            </HoverableTableRow>
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ReusableTable;
