import { useState } from "react";
import creditService, { CreditRequestData, CreditRequestParams } from "../../services/credit_service";

export const useCreditsStore = () => {
    const [balance, setBalance] = useState<string | number>(0);

    // const [requests, setRequests] = useState<CreditRequestData[]>([]);

    const [transactions, setTransactions] = useState<[]>([]);


    const getCreditRequestList = async (requestsFilter: CreditRequestParams, customAction: (item: any) => void) => {
        const response = await creditService.listCreditRequests(requestsFilter);
        if (!!!response) return null;

        const creditList = response?.data ?? []
        const dataWithAdditionalColumns = await Promise.all(creditList.map(async (item: any) => ({//+
            ...item,
            actions: customAction(item)
        })));

        const data = dataWithAdditionalColumns;
        const meta = response?.meta;

        return { data, meta }
    };


    return { balance, getCreditRequestList };

}