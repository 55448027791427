import * as React from 'react';
import { useEffect, useState } from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { Box, Button, Container, MenuItem, Select, Stack, StyledComponentProps, SxProps } from '@mui/material';
import AppDatePicker from '../button/date_picker_button';
import { Col, Row, StyledContainer } from '../styled/styled_flex_container';
import PromptTypo from '../typography/typography_prompt';
import { StyledFillButton } from '../../styled/filled_button';
import { formatDateTime } from '../../helpers/date_time_helper';
import { SPACING_SM, SPACING_XXL } from '../../configs/constants';

const MAX_TOPUP_DATE = new Date();

const CREDIT_STATUS = [
    { value: 'REQUESTED', label: "รออนุมัติ" },
    { value: 'APPROVED', label: "อนุมัติแล้ว" },
    { value: 'REJECTED', label: "ปฏิเสธแล้ว" },
]

const statusSelect = { name: 'status', inputType: 'select', value: CREDIT_STATUS[0]?.value, required: true, placeholder: 'สถานะ', label: 'สถานะ', choices: CREDIT_STATUS, };
const requestDate = { name: 'requestedDate', inputType: 'date', value: '', maxValue: MAX_TOPUP_DATE.toString(), required: true, placeholder: `วันที่แจ้ง`, label: 'วันที่แจ้ง', };
const transactionDate = { name: 'transactionDate', inputType: 'date', value: '', maxValue: MAX_TOPUP_DATE.toString(), required: true, placeholder: `วันที่ฝาก`, label: 'วันที่ฝาก', };


const formFields = [statusSelect, requestDate, transactionDate];

export type creditRequestFilterOptions = {
    status?: any;
    dateFrom: string;
    dateTo: string
}

type CreditTableFilter = {
    style?: StyledComponentProps;
    sx?: SxProps;
    onChanged?: (newValue: any) => void;
}
export default function CreditTableFilter({ style, sx, onChanged }: CreditTableFilter) {
    const [fields, setFields] = useState<AppFormData[]>([...formFields]);
    useEffect(() => {
        setFields(formFields.map(field => ({ ...field })));
    }, []);
    const handleClearForm = () => {
        setFields(formFields.map(field => ({ ...field, value: '' })));
    }
    const handleSubmit = (data: Record<string, string | number>) => { }

    const handleChange = (name: string, value: string) => {
        setFields((prevFields) =>
            prevFields.map((field) =>
                field.name === name ? { ...field, value } : field
            )
        );
    };

    useEffect(() => {
        const newValue = {
            [fields[0].name]: fields[0].value,
            dateFrom: fields[1].value,
            dateTo: fields[2].value,
        }
        console.log({ newValue });
        onChanged?.(newValue);
    }, [fields])


    return (
        <form>
            <StyledContainer style={{ paddingTop: 12 }}>
                <Stack direction='row' spacing={SPACING_SM}>
                    <Stack direction='column'>
                        <PromptTypo>สถานะ</PromptTypo>
                        <Select
                            value={fields[0].value}
                            onChange={(e) => handleChange(fields[0].name, e.target.value)}
                            size="small"
                            style={{ width: '100%', height: SPACING_XXL, minWidth: '200px' }}
                        >
                            {fields[0]?.choices?.map(choice => (
                                <MenuItem key={choice.value} value={choice.value}>
                                    {choice.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Stack>
                <Box sx={{ height: 12 }} />
                <Stack direction='row' spacing={3}>
                    <Stack direction='column'>
                        <PromptTypo>วันที่แจ้ง</PromptTypo>
                        <AppDatePicker
                            name={fields[1].name}
                            placeholder={fields[1].placeholder ?? fields[1].name}
                            value={requestDate.value}
                            error={false}
                            showTimeSelectOnly={false}
                            isDisabledTime={true}
                            isClearable={false}
                            onChange={(date, e) =>
                                handleChange(fields[1].name, formatDateTime(date))} />
                    </Stack>
                    <Stack direction='column'>
                        <PromptTypo>วันที่โอน</PromptTypo>
                        <AppDatePicker
                            name={fields[2].name}
                            placeholder={fields[2].placeholder ?? fields[2].name}
                            value={transactionDate.value}
                            error={false}
                            showTimeSelectOnly={false}
                            isDisabledTime={true}
                            isClearable={false}
                            onChange={(date, e) =>
                                handleChange(fields[2].name, formatDateTime(date))} />
                    </Stack>
                </Stack>
                {/* <Col size={1.5}>
                    <div style={{ height: 15 }}></div>
                    <Box>
                        <StyledFillButton variant="contained" type="submit" style={{ padding: '4px 48px' }}>
                            ค้นหา
                        </StyledFillButton>
                    </Box>
                </Col> */}
            </StyledContainer>

        </form>
    );
}
