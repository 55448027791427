import React from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { User } from '../../configs/constants';
import { useDialog } from '../../stores/providers/dialog_provider';



export type CreditAdjustmentDialogProps = {
    user?: User
    closeDialog?: () => void;
    onSubmit?: (formData: Record<string, string | number>) => void | Promise<void> | undefined | null
};

const formFields: AppFormData[] = [
    {
        name: 'type', value: 'ADJUST', required: true, placeholder: 'ประเภท', label: 'ประเภท', inputType: 'select',
        choices: [
            { value: 'ADJUST', label: 'Adjust' },
        ],
        textFieldProps: { type: 'number', name: '', autoComplete: '' }
    },
    {
        name: 'amount', value: '', required: true, placeholder: 'จำนวน', label: 'จำนวน',
        textFieldProps: { type: 'number', name: '', autoComplete: '' }
    },
    {
        name: 'reason', value: '', placeholder: 'reason', label: 'reason',
        textFieldProps: { type: 'text', name: '', autoComplete: '' }
    },

];
const CreditAdjustmentDialog: React.FC<CreditAdjustmentDialogProps> = ({ user, onSubmit }) => {
    const { closeDialog } = useDialog();
    const handleFormSubmit = (data: Record<string, string | number>) => {
        console.log('Form submitted:', data);
    };

    return (
        <FormBuilder onCancel={() => closeDialog()} formTitle={`จัดการเครดิต : ${user?.username}`} formSubTitle={`เครดิตคงเหลือ : ${user?.totalBalance ?? '100000'}`} containerSize={480} fields={formFields} onSubmit={handleFormSubmit} />
    );
};

export default CreditAdjustmentDialog;
