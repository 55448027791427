import React, { useState, useRef, forwardRef, useEffect } from "react";
import { Button, Container, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, SxProps, TextFieldProps } from '@mui/material';
import PromptTypo from '../typography/typography_prompt';
import StyledTextField from '../styled/styled_textfield';
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../../styled/filled_button';
import AppDatePicker from "../button/date_picker_button";
import SwalDatePicker from "../dialog/swal_date_picker";
import dayjs from "dayjs";
import { generalDateTimeFormat } from "../../configs/constants";
import { hasPermission } from "../../configs/permission";
import { useAuth } from "../../stores/providers/auth_provider";
import { PanoramaFishEye, Visibility, VisibilityOff } from "@mui/icons-material";
export interface AppFormData {
    name: string;
    value: string;
    pattern?: RegExp;
    error?: boolean;
    required?: boolean;
    placeholder?: string;
    label?: string;
    inputType?: string;
    disabled?: boolean;
    choices?: Choice[];
    minValue?: string;
    maxValue?: string;
    textFieldProps?: TextFieldProps | undefined;
    requiredPermission?: string;
    isClearable?: boolean;
}

export interface Choice {
    label: string, value: string, requiredPermission?: string
}

interface FormBuilderProps {
    fields: AppFormData[];
    formTitle?: string;
    formSubTitle?: string;
    onSubmit: (data: Record<string, string | number>) => void;
    onClearData?: () => void;
    onCancel?: () => void;
    sx?: SxProps;
    textFieldProps?: TextFieldProps | undefined;
    col?: number;  // Add col property
    itemsPerRow?: number;
    children?: JSX.Element;
    containerSize?: number;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ formTitle, fields, onSubmit, onClearData, onCancel, sx, textFieldProps, formSubTitle, itemsPerRow, children, containerSize }) => {
    const [formData, setFormData] = useState<AppFormData[]>(fields);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [initialFormData, setInitialFormData] = useState<AppFormData[]>();
    const { user } = useAuth();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(() => {
        setInitialFormData(fields);
    }, [])

    useEffect(() => {
        const emptyRequiredField = fields.find(f => (f.required && (f.value == '' || !f.value)));
        setFormData(fields);
        setIsFormValid(!emptyRequiredField)
    }, [fields]);

    const handleChange = (name: string, value: string) => {
        const updatedFormData = formData.map((field) => {
            return field.name === name ? { ...field, value } : field;
        });
        validateField(name, value);
        setFormData(updatedFormData);

    };

    const validateField = (name: string, value: string) => {
        const field = formData.find(f => f.name === name);
        let hasError = false;

        if (field?.required && !value?.trim()) {
            hasError = true;
        } else if (field?.pattern && !field.pattern.test(value)) {
            hasError = true;
        }
        setErrors((prev) => ({ ...prev, [name]: hasError }));
    };

    const handleSubmit = () => {
        const submittedData = formData.reduce((acc, field) => {
            acc[field.name] = field.value;
            return acc;
        }, {} as Record<string, string | number>);


        onSubmit(submittedData);
    };

    const forcedPutValueOnUnfocused = (name: string, value: string) => {
        const field = formData.find(f => f.name === name);
        let newValue: string = '';
        if (field?.minValue && !(+field.value)) handleChange(name, field.minValue);
        else if (field?.minValue && (+field.value) < (+field.minValue))
            newValue = field.minValue;
        !!newValue.length && handleChange(name, newValue)
    }

    useEffect(() => {
        const someFieldHasError = Object.keys(errors).some((key) => errors[key] === true);
        const someFieldIsEmptyInitial = formData.some(field => field.required === true && field.value == '');
        if (Object.keys(errors).some((key) => errors[key] === true)) setIsFormValid(false);
        else if (formData.some(field => field.required === true && field.value == '')) setIsFormValid(false);
        else setIsFormValid(true);

    }, [formData]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const renderField = (field: AppFormData) => {
        switch (field.inputType) {
            case 'select':
                return (
                    <Select
                        value={field.value}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        disabled={field.disabled}
                        size="small"
                        style={{ width: "100%", height: '56px' }}
                    >
                        {
                            field.choices?.map(choice => {
                                const visible = (!!!choice.requiredPermission) || hasPermission(user.permissions, choice.requiredPermission)
                                return visible && <MenuItem key={choice.value} value={choice.value} >
                                    {choice.label}
                                </MenuItem>
                            })
                        }
                    </Select >
                );


            case 'dateTime':
                return (
                    <AppDatePicker
                        style={{ width: "100%" }}
                        name={field.name}
                        placeholder={field.placeholder ?? field.name}
                        value={null}
                        error={field.error ?? false}
                        isClearable={true}
                        onChange={(date) => {
                            handleChange(field.name, dayjs(date).format())
                        }
                        }
                    />
                );
            case 'date':
                return (
                    <AppDatePicker
                        name={field.name}
                        style={{ width: "100%" }}
                        placeholder={field.placeholder ?? field.name}
                        value={field.value}
                        error={field.error ?? false}
                        showTimeSelectOnly={false}
                        isDisabledTime={true}
                        isClearable={true}
                        onChange={(date) => {
                            handleChange(field.name, `${dayjs(date).format(generalDateTimeFormat)}`)
                        }
                        }
                    />
                );
            case 'password':
                return <OutlinedInput
                    id="password"
                    fullWidth
                    placeholder={field.placeholder ?? field.name}
                    type={showPassword ? 'text' : field.inputType}
                    error={errors[field.name] || false}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    value={field.value}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={
                                    showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

            default:
                return (
                    <StyledTextField
                        name={field.name}
                        placeholder={field.placeholder ?? ''}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        error={errors[field.name] || false}
                        value={field.value}
                        props={field.textFieldProps}
                        disabled={field.disabled}
                        type={field.inputType}
                        onBlur={field.minValue ? () => forcedPutValueOnUnfocused(field.name, field.value) : undefined}
                    />
                );
        }
    }

    return (
        <Container sx={sx ?? {}} maxWidth="lg" style={{ display: 'flex', width: containerSize ?? 640, marginTop: '2rem', padding: '16px 32px' }}>
            <form onSubmit={() => {
                return
            }}>
                <Divider>
                    <PromptTypo fontWeight={'bold'} variant="h5" gutterBottom={formSubTitle ? false : true}>
                        {formTitle ?? ''}
                    </PromptTypo>
                    <PromptTypo variant="h6" gutterBottom color="info">
                        {formSubTitle ?? ''}
                    </PromptTypo>
                </Divider>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {formData.map((field, index) => (
                        <div
                            key={index}
                            style={{
                                width: itemsPerRow ? `${100 / (itemsPerRow ?? 1)}%` : '100%',
                                padding: '5px'
                            }}
                        >
                            <PromptTypo marginY={0.5}>
                                {`${field.label}`}
                                <span style={{ color: '#FF5C00' }}>
                                    {`${field.required ? '*' : ''}`}
                                </span>
                            </PromptTypo>
                            {renderField(field)}
                            {errors[field.name] && (
                                <PromptTypo fontSize={12} color="error" className="p-0">
                                    กรุณากรอกข้อมูลให้ถูกต้อง
                                </PromptTypo>
                            )}
                        </div>
                    ))}
                </div>
                {children}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
                    <StyledFillButton
                        disabled={
                            Object.keys(errors).some((key) => errors[key] === true) ||
                            formData.some((field) => field.required && field.value == ''
                            )
                        }
                        variant="contained" type="button" style={{ padding: '4px 48px' }}
                        onClick={handleSubmit}
                    >
                        <PromptTypo sx={{ padding: '4px 6px' }} variant="body1">
                            ยืนยัน
                        </PromptTypo>
                    </StyledFillButton>

                    {onClearData && (
                        <StyledFillButton sx={{ padding: '4px 12px' }} color="info" size="small" onClick={() => { onClearData() }}>
                            <PromptTypo variant="body1">
                                ล้างข้อมูล
                            </PromptTypo>
                        </StyledFillButton>
                    )}
                    {onCancel && (
                        <StyledTextButton sx={{ padding: '4px 12px' }} variant="outlined" color="secondary" size="small" onClick={onCancel}>
                            <PromptTypo variant="body1">
                                ยกเลิก
                            </PromptTypo>
                        </StyledTextButton>
                    )}
                </div>
            </form>
        </Container >
    );
};

export default FormBuilder;
