import React, { ReactNode, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs';
import ReusableTable, { DataRow } from '../components/table/hoverable_table_row';
import { Box, Button, Divider, IconButton, MenuItem, Pagination, Stack, Tooltip } from '@mui/material';
import { Add, AdjustOutlined, AdjustRounded, Approval, Check, Close, Delete, Edit, FormatLineSpacingRounded, Lock, ManageAccountsRounded, ManageSearchRounded, NoTransferRounded, Password, RequestPage, RequestQuote, RequestQuoteRounded, TouchAppRounded, Visibility } from "@mui/icons-material";
import MenuPopup from '../components/menu_popup/menu_popup';
import PromptTypo from '../components/typography/typography_prompt';
import { User, USER_CREATE_STAFF } from '../configs/constants';
import { Col, Row, StyledContainer } from '../components/styled/styled_flex_container';
import CreditTableFilter, { creditRequestFilterOptions } from '../components/table_filters/credit_table_filter';
import { formatNumberString } from '../components/spring/string_helper';
import userService from '../services/user_service';
import { StyledFillButton, StyledOutlineButton } from '../styled/filled_button';
import creditService, { CreditRequestData, CreditRequestParams, TopUpRequestBody } from '../services/credit_service';
import { useAppSettings } from '../stores/context/app_setting_context';
import { useDialog } from '../stores/providers/dialog_provider';
import CreateUserForm from '../components/dialog/create_user_form';
import Spacer from '../components/spacing/spacer';
import { useCreditsStore } from '../stores/hooks/credits_hook';
import { convertISOStringtoFormattedDateTime, formatDateTime } from '../helpers/date_time_helper';
import DialogService from '../components/dialog/confirmation_by_sweet_alert2';
import { SwalCompleted } from '../components/dialog/swal_alert';
import Alignment from '../components/alignment';



interface TopUpRequestPageProps { }

interface TopUpListData {
    id: number;
    requestedAt: string;
    createdAt: string;
    targetBankAccount: string;
    amount: number;
    remark: string;
    status: string;
    closed: string;
    adminNote: string;
    action?: JSX.Element | null;
}


interface Column {
    label: string;
    field: string;
    align?: 'left' | 'right' | 'center';
    highlighten?: boolean;
    render?: (props: any) => ReactNode;
}

const columns: Column[] = [
    // { label: 'เลขที่', field: 'id' },
    { label: 'รหัสธุรกรรม', field: 'transactionId' },
    { label: 'ชื่อ Owner', field: 'ownerFirstname' },
    { label: 'วันที่ร้องขอ', field: 'requestDatetime' },
    { label: 'วันที่โอน', field: 'transferDatetime' },
    { label: 'ชื่อบัญชีธนาคาร', field: 'bankAccountName' },
    { label: 'จำนวนเงิน', field: 'amount', highlighten: true },
    { label: 'หมายเหตุจากการร้องขอ', field: 'requestRemark' },
    { label: 'สถานะ', field: 'status', highlighten: true },
    { label: 'ปิดเมื่อ', field: 'processDatetime' },
    { label: 'หมายเหตุจาก Admin', field: 'adminRemark' },
    { label: 'อนุมัติ / ปฏิเสธ', field: 'actions' }
];

const CreditApproval = ({ }: TopUpRequestPageProps) => {
    const [owners, setOwners] = useState<User[]>([]);
    const [creditsList, setCreditList] = useState<CreditRequestData[]>([]);;
    const [listMetadata, setListMetadata]: [any, any] = useState({ page: 1, perPage: 10 });
    const { state } = useAppSettings();
    const [dialogId, setDialogId] = useState<number | null>(null);
    const { openDialog, closeDialog } = useDialog();
    const [filter, setFilter] = useState<CreditRequestParams>({});;
    const { getCreditRequestList } = useCreditsStore();

    useEffect(() => {
        setFilter({
            type: 'TOPUP',
            status: 'REQUEST',
            page: 1,
            perPage: 10
        })

        listTopUpCreditRequest();
    }, []);

    async function listTopUpCreditRequest() {
        const filterOptions: CreditRequestParams = {
            status: filter.status,
            dateFrom: filter.dateFrom && dayjs(filter.dateFrom).toISOString(),
            dateTo: filter.dateTo && dayjs(filter.dateTo).toISOString(),
            page: filter.page,
            perPage: filter.perPage,
        }


        creditService.listCreditRequests(filterOptions).then(async res => {
            console.log({ res });
            const creditsListResponse = res?.data ?? [];;
            const dataWithAdditionalColumns = await Promise.all(creditsListResponse.map(async (item: any) => {
                const statusDisplay = item.status === 'APPROVED' ? 'อนุมัติแล้ว' : item.status == 'REJECTED' ? 'ปฏิเสธแล้ว' : ''
                return {
                    ...item,
                    actions: !!statusDisplay ? <Alignment position='center'>
                        <PromptTypo fontWeight='bold' color='primary' variant='subtitle1'>อนุมัติแล้ว</PromptTypo>
                    </Alignment> : _getTopUpRowAction(item),
                    requestDatetime: convertISOStringtoFormattedDateTime(item.requestDatetime),
                    transferDatetime: convertISOStringtoFormattedDateTime(item.transferDatetime),
                    processDatetime: convertISOStringtoFormattedDateTime(item.processDatetime),
                }
            }));

            console.log('dataWithAdditionalColumns:', dataWithAdditionalColumns);
            setCreditList(dataWithAdditionalColumns);
            setListMetadata(res?.meta ?? {});
        })
    }

    const handleApprove = async (item: Record<string, any>) => {
        closeDialog(dialogId);

        const dialogContent = `
        <div style="display: flex; flex-direction: column; text-align: center;">
            <h2 style="font-family: 'Prompt', sans-serif; font-weight: bold;">ยืนยันการอนุมัติการเติมเครดิต</h6>
            <h4 style="font-family: 'Prompt', sans-serif;">
                จำนวน <span style="color: ${state.theme.palette.secondary.main};">${item.amount}</span> 
                ให้แก่ <span style="color: green;">${item.ownerFirstname} ${item.ownerLastname}</span> หรือไม่?
            </h6>
        </div>
    `;

        DialogService.open(
            {
                html: dialogContent,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: state.theme.palette.success.main,
                cancelButtonColor: state.theme.palette.error.main,
                showCancelButton: true,
            },
            async () => {
                await creditService.creditApproval(item.id, 'APPROVED').then(async (res) => {
                    !!res && SwalCompleted();
                    !!res && setFilter(filter);
                });
            }
        );



    }

    const handleReject = (id: number | string) => { }

    const handleSearch = (newValue: creditRequestFilterOptions) => {
        console.log("NEWDATA ", { ...filter, dateFrom: newValue.dateFrom, dateTo: newValue.dateTo, status: newValue.status })
        setFilter({ ...filter, dateFrom: newValue.dateFrom, dateTo: newValue.dateTo, status: newValue.status });
        listTopUpCreditRequest()
    }

    const _getTopUpRowAction = (item: Record<string, any>) => {
        return <Stack direction={'row'} width='100%' justifyContent='flex-end'>
            <Tooltip title="อนุมัติ" arrow>
                <StyledOutlineButton
                    onClick={() => handleApprove(item)}
                    sx={{
                        ':hover': {
                            backgroundColor: state.theme.palette.success.main,
                            borderColor: state.theme.palette.success.main,
                            color: 'white',
                        }
                    }}>
                    <Stack direction='row'>
                        <Check sx={{ margin: '0px 8px' }} />
                    </Stack>
                </StyledOutlineButton>
            </Tooltip>

            <Box sx={{ width: '16px' }} />
            <Tooltip title="ปฏิเสธ" arrow>
                <StyledOutlineButton
                    sx={{
                        ':hover': {
                            backgroundColor: state.theme.palette.error.main,
                            borderColor: state.theme.palette.error.main,
                            color: 'white',
                        }
                    }}>
                    <Stack direction='row'>
                        <Close sx={{ margin: '0px 8px' }} />
                    </Stack>
                </StyledOutlineButton>
            </Tooltip>
        </Stack >
    }

    function handleOnPageChange(e: React.ChangeEvent<any>, page: number) {
        listMetadata({ page: page ?? 1 });
    }

    function handleOnFilterChange(newValue: any) {
        console.log({ valueInParent: newValue });
        setFilter({ ...filter, ...newValue });
    }

    const handleCreditAdjustment = (credit: CreditRequestData) => {
        const dialogId = openDialog(<CreateUserForm user={{}} onSubmit={() => { }} />);
        setDialogId(dialogId);
    };

    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายการขอเติมเครดิต</PromptTypo>
            <CreditTableFilter onChanged={handleOnFilterChange} sx={{ width: '100%' }} />
            <Spacer size='md' />
            <StyledFillButton sx={{ marginLeft: '16px' }} onClick={(newValue) => handleSearch(newValue as any)}>ค้นหา</StyledFillButton>
            {!!creditsList.length && <ReusableTable
                sx={{ width: '100%' }}
                columns={columns}
                data={creditsList as unknown as DataRow[] || []}
            />}
            {
                <Pagination style={{ marginTop: "1em" }} hidden={!!!creditsList.length} count={listMetadata?.totalPages ?? 0} defaultPage={listMetadata?.page ?? 1}
                    onChange={handleOnPageChange} />
            }
        </StyledContainer >
    )
}

export default CreditApproval;