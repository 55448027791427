import React, { CSSProperties, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { StyledOutlineButton, StyledOutlineDatepickerButton } from '../../styled/filled_button';
import 'react-datepicker/dist/react-datepicker.css';
import { generalDateFormat, generalDateTimeFormat, SPACING_XXL } from '../../configs/constants';
import { formatDateTime } from '../../helpers/date_time_helper';
import PromptTypo from '../typography/typography_prompt';
import { da, th } from 'react-day-picker/locale';
import { IconButton, Stack } from '@mui/material';
import ClearRounded from '@mui/icons-material/ClearRounded';
import 'dayjs/locale/th';
interface AppDatePickerProps {
    name: string;
    onChange?: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
    value: string | null; // Allow null as a value
    placeholder: string;
    showTimeSelectOnly?: boolean;
    isDisabledTime?: boolean;
    style?: CSSProperties;
    error?: boolean;
    isClearable?: boolean;
    onClearData?: () => void;
}

const AppDatePicker = (props: AppDatePickerProps) => {
    const { value, onChange, onClearData, isClearable } = props;
    const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);

    useEffect(() => {
        const dateFormatted = dayjs(value).format(generalDateTimeFormat)
        setDate(dayjs(dateFormatted).isValid() ? new Date(dateFormatted) : null);
    }, [value]);

    const handleOnDateChanged = (date: Date | null) => {

        if (onChange) {
            onChange(dayjs(date).toDate());
        }
        setDate(dayjs(date).toDate());
    };

    useEffect(() => {
        if (onClearData) {
            onClearData();
            handleOnDateChanged(null);
        }
    }, [onClearData]);

    return (
        <DatePicker
            fixedHeight={true}
            maxDate={new Date()}
            selected={date}
            showTimeCaption
            locale={th}
            showTimeInput
            placeholderText={generalDateFormat}
            shouldCloseOnSelect={false}
            onChange={handleOnDateChanged}
            customInput={
                <Stack direction="row">
                    <StyledOutlineDatepickerButton sx={{
                        padding: '4px 16px 4px 16px', height: SPACING_XXL, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <Stack direction="row" sx={{ width: '230px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <PromptTypo variant="body1">
                                {`${dayjs(date).isValid() ? dayjs(date).format(generalDateTimeFormat) : 'ยังไม่ได้เลือกวันที่'}`}
                            </PromptTypo>
                            <IconButton disabled={!!!date} sx={{ marginLeft: '16px' }} onClick={() => setDate(null)}>
                                <ClearRounded sx={{ ":hover": { color: 'red' } }} />
                            </IconButton>
                        </Stack>
                    </StyledOutlineDatepickerButton>
                </Stack>
            }
        />
    );
};

export default AppDatePicker;
