export function hasPermission(userPermissions: any, requiredPermissions: string | string[]): boolean {
    try {
        if (!Array.isArray(userPermissions)) {
            return false;
        }

        const permissionsToCheck = Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions];

        return permissionsToCheck.some(permission => userPermissions.includes(permission));
    } catch (error) {
        console.log(error);
        return false;
    }
}


export function hasAnyPermission(userPermissions: any, requiredPermissions: string[]): boolean {
    try {
        if (!Array.isArray(userPermissions) || !Array.isArray(requiredPermissions)) {
            return false;
        }
        return requiredPermissions.every(permission => userPermissions.includes(permission));
    } catch (error) {
        console.log(error);
        return false;
    }
}


export function hasSomePermission(userPermissions: string[], requiredPermissions: string[]): boolean {
    return requiredPermissions.some(permission => userPermissions?.includes(permission));
}