// EditProfileForm.tsx
import React from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';

const formFields: AppFormData[] = [
    { name: 'name', value: '', required: true, placeholder: 'ชื่อ', label: 'ชื่อ' },
    { name: 'phone', value: '', pattern: /^\d{10}$/, required: true, placeholder: 'เบอร์โทรศัพท์', label: 'เบอร์โทรศัพท์' },
    { name: 'email', value: '', pattern: /\S+@\S+\.\S+/, required: true, placeholder: 'อีเมล', label: 'อีเมล' },
];

const EditProfileForm: React.FC = () => {
    const handleFormSubmit = (data: Record<string, string | number>) => {
        console.log('Form submitted:', data);
    };

    return (
        <FormBuilder fields={formFields} onSubmit={handleFormSubmit} />
    );
};

export default EditProfileForm;
