
import React from 'react';
import { useFullScreenLoading } from '../../stores/providers/backdrop_progress_provider';
import { Button } from '@mui/material';

export default function FullSCreenBackdropLoadingIndicator() {
    const { startFullScreenLoading } = useFullScreenLoading();
    const UiBtn = <Button onClick={startFullScreenLoading}>Test loading</Button>
    return (
        <>{UiBtn}</>
    );
}
