import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { groupRoutesByPosition, RouteModel, routes } from '../../configs/routes';
import { NotFoundPage } from '../../pages';
import ProtectedRoute from '../../configs/protected_routes';
import { useAuth } from '../../stores/providers/auth_provider';
import { useUserToken } from '../../stores/hooks/user_token';
import { hasPermission } from '../../configs/permission';
import { useCreditsStore } from '../../stores/hooks/credits_hook';

interface RouteConfig {
    key: React.Key | null | undefined;
    path: string | undefined;
    component:
    | React.ReactElement
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | string
    | number
    | boolean
    | null
    | undefined;
}

const RouterOutlet: React.FC = () => {
    const { outerRoutes, internalRoutes } = groupRoutesByPosition();
    const { isAuthenticated } = useAuth();
    const { userToken } = useUserToken();
    const { balance } = useCreditsStore();
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (!userToken) {
            return navigate('/login', { replace: true })
        }
    }, [userToken, isAuthenticated]);

    const renderInternalRoutes = () => {
        const allowedRoutes = filteredRoutes(internalRoutes);
        return allowedRoutes.map((e: RouteConfig) => (
            <Route key={e.key} path={e.path} element={e.component} />
        ));
    }
    const renderOuterRoutes = () =>
        outerRoutes.map((route: RouteConfig) => (
            <Route key={route.key} path={route.path} element={route.component} />
        ));

    const filteredRoutes = (routes: RouteModel[]) => routes.filter(route => route.position === 'side')
        .filter(route => {
            if (!!route.requiredPermission) {
                const isMatchedPermission = hasPermission(user?.permissions, route.requiredPermission ?? '-');
                return route.implemented && isMatchedPermission
            }
            else return route.implemented;
        });

    return (
        <Routes>
            <Route element={<ProtectedRoute />}>{renderInternalRoutes()}</Route>
            <Route>{renderOuterRoutes()}</Route>
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default RouterOutlet;