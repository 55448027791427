import React from 'react';
import { SPACING_XS, SPACING_SM, SPACING_MD, SPACING_LG, SPACING_XL, SPACING_XXL } from '../../configs/constants';

// Import the spacing constants

interface SpacerProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';  // Predefined spacing size
    width?: number;  // Optional width for horizontal spacing (in pixels)
    height?: number; // Optional height for vertical spacing (in pixels)
}

const Spacer: React.FC<SpacerProps> = ({ size, width, height }) => {
    // Determine the spacing value based on the 'size' prop
    let spacingValue = '';

    switch (size) {
        case 'xs':
            spacingValue = SPACING_XS;
            break;
        case 'sm':
            spacingValue = SPACING_SM;
            break;
        case 'md':
            spacingValue = SPACING_MD;
            break;
        case 'lg':
            spacingValue = SPACING_LG;
            break;
        case 'xl':
            spacingValue = SPACING_XL;
            break;
        case 'xxl':
            spacingValue = SPACING_XXL;
            break;
        default:
            break;
    }

    // If no predefined size is passed, fall back to width/height
    const style = {
        width: width ? `${width}px` : spacingValue ? spacingValue : undefined,
        height: height ? `${height}px` : spacingValue ? spacingValue : undefined,
    };

    return <div style={style} />;
};

export default Spacer;
