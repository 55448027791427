import { Button, Container, Typography } from '@mui/material';
import React from 'react';

export default function NotFoundPage() {
    const handleGoBack = () => {
        // Logic to navigate back or to a specific route
        window.history.back();
    };

    return (
        <Container style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h1" color="error">
                404
            </Typography>
            <Typography variant="h4" gutterBottom>
               Not Found
            </Typography>
            <Typography variant="body1" paragraph>
                This page you are looking for does't exist or has been moved
            </Typography>
            <Button variant="contained" color="primary" onClick={handleGoBack}>
                Go Back
            </Button>
        </Container>
    );
}

