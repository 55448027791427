import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Collapse,
    Container,
    Grow,
    Paper,
    Typography,
    Zoom,
} from '@mui/material';
import {
    useTransition,
    useSpring,
    useChain,
    config,
    animated,
    useSpringRef,
} from '@react-spring/web';

import FormBuilder, { AppFormData, Choice } from '../components/form/form_builder';
import { Button } from '@react-login-page/page10';
import ReusableTable, { DataRow } from '../components/table/hoverable_table_row';
import dayjs from 'dayjs';
import { hasPermission } from '../configs/permission';
import { useAuth } from '../stores/providers/auth_provider';
import { StyledFillButton, StyledOutlineButton } from '../styled/filled_button';
import creditService, { BankAccountResponse, CreditRequestData, CreditRequestParams, ResponseWithMeta, TopUpRequestBody } from '../services/credit_service';
import { EmptyComponent } from '../components/blank/blank_table';
import { generalDateTimeFormat } from '../configs/constants';
import { formatDateTime } from '../helpers/date_time_helper';
import SpringHero from '../components/spring/spring_hero';
import { Col, StyledContainer } from '../components/styled/styled_flex_container';
import { formatNumberString } from '../components/spring/string_helper';
import PromptTypo from '../components/typography/typography_prompt';
import { useCreditsStore } from '../stores/hooks/credits_hook';
import { SwalCompleted } from '../components/dialog/swal_alert';

const MIN_TOPUP_AMOUNT = 5000;
const MAX_TOPUP_DATE = new Date();
const BANK_ACCOUNT_LIST = [{ value: '1', label: "กรุงเทพ 0000000 นาย เอ บี" }]

interface TopUpData {
    id: number;
    requestedAt: string;
    createdAt: string;
    targetBankAccount: string;
    amount: number;
    remark: string;
    status: string;
    closed: string;
    adminNote: string;
}

interface Column {
    label: string;
    field: string;
    highlighten?: boolean;
    render?: (props: any) => ReactNode;
}

type ListPagination = {
    page: number | undefined;
    perPage: number | undefined;
    total?: number | undefined;
}

const formFields: AppFormData[] = [
    { name: 'bankAccount', inputType: 'select', value: BANK_ACCOUNT_LIST[0]?.value, required: true, placeholder: 'บัญชีธนาคาร', label: 'ฝากเข้าบัญชี', choices: BANK_ACCOUNT_LIST },
    { name: 'amount', value: '', required: true, placeholder: `จำนวนเงิน (ขั้นต่ำ 1 บาท )`, label: 'จำนวนเงิน', minValue: `${1}` },
    { name: 'transferedDate', inputType: 'dateTime', value: '', maxValue: MAX_TOPUP_DATE.toString(), required: true, placeholder: `กรุณาเลือกวันและเวลาที่ฝาก`, label: 'วันและเวลาที่ฝาก', isClearable: true },
    { name: 'remark', value: '', required: false, placeholder: `หมายเหตุ`, label: 'หมายเหตุ', },
];

export const columns: Column[] = [
    { label: 'เลขที่', field: 'id' },
    { label: 'วันที่แจ้ง', field: 'requestedAt' },
    { label: 'วันที่ฝาก', field: 'createdAt' },
    { label: 'บัญชี', field: 'targetBankAccount' },
    { label: 'จำนวนเงิน', field: 'amount', render: (value: any) => formatNumberString(value), highlighten: true },
    { label: 'หมายเหตุ', field: 'requestRemark' },
    {
        label: 'status', field: 'status', highlighten: true, render: (value: string) => {
            if (value === 'REQUEST') return 'รออนุมัติ';
            if (value === 'APPROVED') return 'อนุมัติแล้ว';
            return value;

        },
    },
    { label: 'ปิดเมื่อ', field: 'closed' },
    { label: 'Admin note', field: 'adminNote' },
];

const TopUpPage: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState<AppFormData[]>([...formFields]);
    const [topUps, setTopUps] = useState<TopUpData[]>([]);
    const [banks, setBanks] = useState<Choice[] | []>([]);
    const [bankResponse, setBankResponse] = useState<BankAccountResponse[] | []>([]);
    const [minAmount, setMinAmount] = useState<string | []>('1');
    const [createable, setCreateable] = useState<boolean>(true);
    const [requestHistoryVisible, setRequestHistoryVisible] = useState<boolean>(true);
    const { user: currentUser } = useAuth();
    const [pagination, setPagination] = useState<ListPagination>({ page: 1, perPage: 30, total: 0 });
    const prevPagination = useRef<ListPagination>();
    const { getCreditRequestList } = useCreditsStore();

    useEffect(() => {
        const isCreditRequestMatched = hasPermission(currentUser.permissions, 'CREDIT_REQUEST_CREATE');
        const isRequestHistoryMatched = hasPermission(currentUser.permissions, 'CREDIT_REQUEST_LIST');
        setCreateable(isCreditRequestMatched);
        setRequestHistoryVisible(isRequestHistoryMatched);
        setPagination({ page: 1, perPage: 30, total: 30 });
        _getBankAccount();
    }, []);

    useEffect(() => {
        if (prevPagination.current !== pagination) {
            _getTopUpRequestHistories();
        }

        prevPagination.current = pagination;
    }, [pagination]);

    const formattedDateTIme = (dateTime: string | Date | undefined) => {
        return dayjs(dateTime).isValid() ? dayjs(dateTime).format(generalDateTimeFormat) : '-'
    }

    const _getBankAccount = async () => {
        const res: BankAccountResponse[] | [] = await creditService.listBankAccount();
        setBankResponse(res);
        const fieldsTmp = formFields;
        if (res) {
            const bankMapToState = res.map((bank) => ({
                label: `${bank.bankName} - ${bank.bankAccountNumber} - ${bank.bankAccountName}`,
                value: `${bank.id}`,
                minTransaction: bank.minimumAmount
            }) as Choice) as Choice[] | [];
            fieldsTmp[0].choices = bankMapToState;
            fieldsTmp[1].placeholder = `จำนวนเงินขั้นต่ำ ${minAmount} บาท`
            setBanks(bankMapToState);
            setFields(formFields.map(field => ({ ...field })));
        }
    }

    useEffect(() => {
        const fieldTemp = fields[1];
        const findCurrentMinAmount = bankResponse.find(f => f.id === +fields[0].value)?.minimumAmount ?? '';
        fieldTemp.placeholder = `จำนวนเงินขั้นต่ำ ${findCurrentMinAmount} บาท`
        setFields([
            fields[0],
            fieldTemp,
            fields[2]
        ])

    }, [fields[0]])


    const _getTopUpRequestHistories = async () => {
        const requestOptions: CreditRequestParams = {
            type: 'TOPUP',
            page: pagination.page,
            perPage: pagination.perPage,
        }
        await getCreditRequestList(requestOptions, () => { }).then(res => {
            if (res && Array.isArray(res.data) && res.data.length !== 0) {
                const data: TopUpData[] = res.data.map((item: CreditRequestData) => ({
                    id: item.id,
                    requestedAt: formattedDateTIme(item.requestDatetime),
                    createdAt: formattedDateTIme(item.transferDatetime),
                    targetBankAccount: item.bankAccountId,
                    amount: item.amount,
                    remark: item.requestRemark,
                    status: item.status,
                    closed: formattedDateTIme(item.processDatetime),
                    adminNote: item.adminRemark?.trim() ?? '-',
                } as unknown as TopUpData));
                setTopUps(data);
            }
            else {
                setTopUps([]);
            }
        })
    }

    const createTopUpRequest = async (formData: Record<string, string | number>) => {
        const requestBody: TopUpRequestBody = {
            ownerId: currentUser.ownerId ?? 0,
            bankAccountId: formData.bankAccount,
            amount: formData.amount,
            requestDatetime: dayjs().toISOString(),
            transferDatetime: dayjs(formData.transferedDate).toISOString(),
            requestRemark: `${formData.remark}`,
            type: 'TOPUP'
        };
        const response = await creditService.creditTopUpRequest(requestBody);

        if (!!response) {
            setOpen(false);
            SwalCompleted();
            await _getTopUpRequestHistories();
        }
    }

    const handleFormSubmit = (data: Record<string, string | number>) => {
        createTopUpRequest(data);
    };

    const handleClearForm = () => {
        setFields(formFields.map(field => ({ ...field })));
    }

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const createTopUpRequestForm = () => {
        return (
            <Box sx={{ minHeight: 50, padding: 0, width: '100%' }}>
                <Collapse in={!open}>
                    <StyledOutlineButton
                        color="primary"
                        onClick={handleToggle}
                        style={{ marginBottom: '1rem' }}
                    >
                        แจ้งเติมเครดิต
                    </StyledOutlineButton>
                </Collapse>
                <Collapse in={open} timeout={500}>
                    <animated.div>
                        <SpringHero open={open} setOpen={setOpen}>
                            <form>
                                <FormBuilder
                                    formTitle="แจ้งเติมเครดิต"
                                    fields={fields}
                                    onSubmit={handleFormSubmit}
                                    onCancel={handleToggle}
                                />
                            </form>
                        </SpringHero>
                    </animated.div>
                </Collapse>
            </Box>
        );
    };

    return (
        <StyledContainer>
            <Box sx={{ padding: 1 }}>
                {createable && createTopUpRequestForm()}
                {requestHistoryVisible && (
                    topUps.length !== 0 ?
                        <ReusableTable columns={columns} data={topUps as unknown as DataRow[]} />
                        : <EmptyComponent displayedText='ไม่พบข้อมูลประวัติการแจ้งเติมเครดิต' />
                )}
            </Box>
        </StyledContainer>

    );
};

export default TopUpPage;
