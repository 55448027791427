import {
    AttachMoney,
    ContactMail,
    CreditScore,
    Description,
    EmojiPeople,
    Group,
    Home,
    Info,
    Lock,
    Login,
    Payment,
    Person,
    Report,
    Settings,
    Tune
} from '@mui/icons-material';
import { HomePage } from '../pages';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddIcon from '@mui/icons-material/Add';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BlockIcon from '@mui/icons-material/Block';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import React from 'react';
import UsersPage from '../pages/users_page';
import LoginPage from '../pages/login_page';
import TopUpPage from '../pages/top_up_page';
import CreditTransactionPage from '../pages/credit_txn_w';
import CreditApproval from '../pages/credit_approval';

export interface RouteModel {
    path: string;
    component: JSX.Element;
    key: string;
    icon?: JSX.Element;
    children?: RouteModel[];
    label: string;
    position?: 'side' | 'top' | 'child' | 'outer',
    implemented?: boolean; // เพื่อกรองเฉพาะรายการที่ได้พัฒนาแล้ว (ใช้ Dev)
    requiredPermission?: string | string[]
};

export const routes: RouteModel[] = [
    {
        path: '/',
        component: <HomePage />,
        key: 'home',
        icon: <Home />,
        label: 'หน้าแรก',
        children: [],
        position: 'side',
        implemented: true,
    },
    {
        path: '/user-management',
        component: <UsersPage />,
        key: 'user-management',
        label: 'การจัดการผู้ใช้งาน',
        icon: <EmojiPeople />,
        position: 'side',
        implemented: true,
        requiredPermission: ['USER_LIST', 'USER_LIST_SUPER_ADMIN', 'USER_LIST_AGENT', 'USER_LIST_STAFF', 'USER_LIST_OWNER']
    },
    {
        path: '/my-profile',
        component: <>MY PROFILE</>,
        key: 'my-profile',
        icon: <Home />,
        label: 'ข้อมูลส่วนตัว',
        children: [],
        position: 'top'
    },
    {
        path: '/number-reports',
        component: <></>,
        key: 'number-reports',
        label: 'ดูเลขทั้งหมด',
        icon: <Report />,
        position: 'side'
    },
    {
        path: '/close-lottery',
        component: <></>,
        key: 'close-lottery',
        label: 'ปิดหวย',
        icon: <CloseIcon />,
        position: 'side'
    },
    {
        path: '/view-ticket',
        component: <></>,
        key: 'view-ticket',
        label: 'ดูโพย',
        icon: <ReceiptIcon />,
        position: 'side'
    },
    {
        path: '/add-ticket',
        component: <></>,
        key: 'add-ticket',
        label: 'แทงโพย',
        icon: <AddIcon />,
        position: 'side'
    },
    {
        path: '/summary',
        component: <></>,
        key: 'summary',
        label: 'ยอดสรุปเป็นใบ',
        icon: <SummarizeIcon />,
        position: 'side'
    },
    {
        path: '/full-number',
        component: <></>,
        key: 'full-number',
        label: 'เลขเต็ม/อั้น',
        icon: <BlockIcon />,
        position: 'side'
    },
    {
        path: '/fight-upper',
        component: <></>,
        key: 'fight-upper',
        label: 'สู้บน',
        icon: <ArrowUpwardIcon />,
        position: 'side'
    },
    {
        path: '/fight-lower',
        component: <></>,
        key: 'fight-lower',
        label: 'สู้ล่าง',
        icon: <ArrowDownwardIcon />,
        position: 'side'
    },
    {
        path: '/total-ticket',
        component: <></>,
        key: 'total-ticket',
        label: 'ยอดแทงออก',
        icon: <ReceiptLongIcon />,
        position: 'side'
    },
    {
        path: '/past-slip',
        component: <></>,
        key: 'past-slip',
        label: 'ดูโพยย้อนหลัง',
        icon: <ReceiptLongIcon />,
        position: 'side'
    },
    {
        path: '/past-lottery',
        component: <></>,
        key: 'past-lottery',
        label: 'ดูหวยย้อนหลัง',
        icon: <ReceiptLongIcon />,
        position: 'side'
    },
    {
        path: '/report',
        component: <></>,
        key: 'report',
        label: 'รายงาน',
        icon: <Report />,
        position: 'side'
    },
    {
        path: '/settings',
        component: <></>,
        key: 'settings',
        label: 'ตั้งค่า',
        icon: <Settings />,
        position: 'side'
    },
    {
        path: '/member-prices',
        component: <></>,
        key: 'member-prices',
        label: 'ราคาสมาชิก',
        icon: <AttachMoney />,
        position: 'side'
    },
    {
        path: '/fight-prices',
        component: <></>,
        key: 'fight-prices',
        label: 'ราคาตั้งสู้',
        icon: <AttachMoney />,
        position: 'side'
    },
    {
        path: '/bet-output',
        component: <></>,
        key: 'bet-output',
        label: 'เจ้ามือแทงออก',
        icon: <ReceiptIcon />,
        position: 'side'
    },
    {
        path: '/limit-setting',
        component: <></>,
        key: 'limit-setting',
        label: 'ตั้งยอดอั้น',
        icon: <Tune />,
        position: 'side'
    },

    {
        path: '/credit-list',
        component: <CreditTransactionPage></CreditTransactionPage>,
        key: 'credit-list',
        label: 'รายการเครดิต',
        icon: <CreditScore />,
        position: 'side',
        implemented: true,
        requiredPermission: 'CREDIT_TXN_LIST'
    },
    {
        path: '/credit-approval',
        component: <CreditApproval></CreditApproval>,
        key: 'credit-approval',
        label: 'คำขอการทำรายการเครดิต',
        icon: <CreditScore />,
        position: 'side',
        implemented: true,
        requiredPermission: 'CREDIT_REQUEST_APPROVE_REJECT'
    },
    {
        path: '/top-up',
        component: <TopUpPage></TopUpPage>,
        key: 'top-up',
        label: 'เติมเงิน',
        icon: <Payment />,
        position: 'side',
        implemented: true,
        requiredPermission: 'CREDIT_REQUEST_CREATE'
    },
    {
        path: '/dealer-info',
        component: <></>,
        key: 'dealer-info',
        label: 'ข้อมูลเจ้ามือ',
        icon: <Info />,
        position: 'side'
    },
    {
        path: '/contact-baby500',
        component: <></>,
        key: 'contact-baby500',
        label: 'ติดต่อ baby500',
        icon: <ContactMail />,
        position: 'side'
    },
    /// -------GROUP----------- ///
    {
        path: '/content',
        component: <></>,
        key: 'content',
        label: 'เนื้อหา',
        icon: <Description />,
        position: 'side'
    },
    {
        path: '/change-password',
        component: <></>,
        key: 'change-password',
        label: 'เปลี่ยนรหัสผ่าน',
        icon: <Lock />,
        position: 'side'
    },
    {
        path: '/login',
        component: <LoginPage />,
        key: 'login',
        label: 'เข้าสู่ระบบ',
        icon: <Login />,
        position: 'outer',
        implemented: true,
    },
    {
        path: '/profile',
        component: <>Profile Page</>,
        key: 'profile',
        label: 'แก้ไขข้อมูลส่วนตัว',
        icon: <Person />,
        position: 'top'
    },
];

export const groupRoutesByPosition = () => {
    const outerRoutes = routes.filter(route => route.position === 'outer');
    const internalRoutes = routes.filter(route => route.position === 'side');
    return { outerRoutes, internalRoutes };
};
