import React, { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

type Position =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'centerLeft'
    | 'center'
    | 'centerRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';

interface AlignmentProps {
    position?: Position;
    children: ReactNode;
}

const positionStyles: Record<Position, SxProps> = {
    topLeft: { justifyContent: 'flex-start', alignItems: 'flex-start' },
    topCenter: { justifyContent: 'center', alignItems: 'flex-start' },
    topRight: { justifyContent: 'flex-end', alignItems: 'flex-start' },
    centerLeft: { justifyContent: 'flex-start', alignItems: 'center' },
    center: { justifyContent: 'center', alignItems: 'center' },
    centerRight: { justifyContent: 'flex-end', alignItems: 'center' },
    bottomLeft: { justifyContent: 'flex-start', alignItems: 'flex-end' },
    bottomCenter: { justifyContent: 'center', alignItems: 'flex-end' },
    bottomRight: { justifyContent: 'flex-end', alignItems: 'flex-end' },
};

const Alignment: React.FC<AlignmentProps> = ({ position = 'center', children }) => {
    const alignmentStyles = positionStyles[position];

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                ...alignmentStyles,
            }}
        >
            {children}
        </Box>
    );
};

export default Alignment;
