import React, { useState } from 'react';
import { Button, Drawer } from '@mui/material';
import MenuContent from './ menu_content';
import './styles.css';

const drawerWidth = 240;
interface SideMenuProps {
    children?: any;
    isOpen?: boolean
}

const SideMenu = ({ children, isOpen }: SideMenuProps) => {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen)
    };
    return (
        <>
            <Drawer
                anchor='left'
                variant='persistent' open={isOpen} onClose={toggleDrawer(false)}
                sx={{ width: drawerWidth }}
            >
                <div style={{ width: drawerWidth }}>
                    <MenuContent />
                </div>
            </Drawer>
        </>
    );
};


export default SideMenu;
