import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export default function PromptTypo(props: TypographyProps) {
    return (
        <Typography variant={props.variant ?? 'body1'} fontFamily='prompt'  {...props}>
            {props.children}
        </Typography>
    );
}

